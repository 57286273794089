<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <b-form-group label="Job Type" label-for="job_type" label-class="form-label" v-slot="{ ariaDescribedby }">
      <b-form-radio
        :disabled="$route.params.id"
        v-model="job.job_type"
        :aria-describedby="ariaDescribedby"
        value="standard"
      >
        <span>Standard</span>
        <span style="font-style: italic; font-size: 14px; color: grey">5 Job Credits will be deducted</span>
        <br />
        <span style="font-size: 15px; color: grey">Jobs accessible to all Transition schools</span>
      </b-form-radio>
      <b-form-radio
        :disabled="$route.params.id"
        v-model="job.job_type"
        :aria-describedby="ariaDescribedby"
        value="local"
      >
        <span>Local</span>
        <span style="font-style: italic; font-size: 14px; color: grey">1 Job Credit will be deducted</span>
        <br />
        <span style="font-size: 15px; color: grey"
          >Jobs are exclusive to the partnered school of the employer</span
        ></b-form-radio
      >
      <b-form-invalid-feedback :state="!$v.job.job_type.required && $v.job.job_type.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Title" label-for="title" label-class="form-label">
      <b-form-input
        id="title"
        placeholder="Enter job's title."
        v-model.trim="job.title"
        required
        :state="!$v.job.title.required && $v.job.title.$dirty ? false : null"
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Description" label-for="description" label-class="form-label">
      <editor
        :init="{
          height: 400,
          menubar: 'edit view insert format tools table help',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
        }"
        v-model="job.description"
        placeholder="Enter job's description."
      />
      <b-form-invalid-feedback :state="!$v.job.description.required && $v.job.description.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Job Status" label-for="status" label-class="form-label" v-if="$route.params.id">
      <v-select
        id="status"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.is_active.required && $v.job.is_active.$dirty }"
        label="text"
        v-model="job.is_active"
        :reduce="option => option.value"
        placeholder="Select job status"
        :options="activeOptions"
        :clearable="false"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="job.is_active === null" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.job.is_active.required && $v.job.is_active.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <div class="row">
      <b-form-group label="Type" label-for="type" label-class="form-label" class="col-md-6">
        <v-select
          id="type"
          class="form-control v-select-custom"
          :class="{ 'is-invalid': !$v.job.type.required && $v.job.type.$dirty }"
          label="text"
          v-model="job.type"
          :reduce="option => option.value"
          placeholder="Select job type"
          :options="typeOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!job.type" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.job.type.required && $v.job.type.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Schedule" label-for="schedule" label-class="form-label" class="col-md-6">
        <v-select
          id="schedule"
          class="form-control v-select-custom"
          :class="{ 'is-invalid': !$v.job.schedule.required && $v.job.schedule.$dirty }"
          label="text"
          v-model="job.schedule"
          :reduce="option => option.value"
          placeholder="Select job schedule"
          :options="scheduleOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!job.schedule" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.job.schedule.required && $v.job.schedule.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>
    </div>

    <b-form-group label="Application Link" label-for="applicationLink" label-class="form-label">
      <b-form-input
        id="applicationLink"
        placeholder="Enter application link for the job."
        v-model.trim="job.application_link"
        :state="
          (!$v.job.application_link.required || !$v.job.application_link.url) && $v.job.application_link.$dirty
            ? false
            : null
        "
      />
      <b-form-invalid-feedback v-if="!$v.job.application_link.url"
        >Not a valid link. Please make sure to enter a valid link e.g. https://www.example.com.</b-form-invalid-feedback
      >
      <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <!-- Address Fields -->
    <b-form-group label="Address" label-for="address" label-class="form-label">
      <b-form-input
        id="address"
        placeholder="Enter address"
        v-model="job.address"
        required
        :state="!$v.job.address.required && $v.job.address.$dirty ? false : null"
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <div class="row">
      <b-form-group label="Zipcode" label-for="zipcode" label-class="form-label" class="col-md-6">
        <b-form-input
          id="zipcode"
          placeholder="Enter zipcode"
          v-model="job.zipcode"
          required
          :state="!$v.job.zipcode.required && $v.job.zipcode.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="City" label-for="city" label-class="form-label" class="col-md-6">
        <b-form-input
          id="city"
          placeholder="Enter city"
          v-model="job.city"
          required
          :state="!$v.job.city.required && $v.job.city.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>
    </div>

    <div class="row">
      <b-form-group label="State" label-for="state" label-class="form-label" class="col-md-6">
        <v-select
          id="state"
          class="form-control v-select-custom"
          label="text"
          :state="!$v.job.state.required && $v.job.state.$dirty ? false : null"
          v-model="job.state"
          :reduce="state => state.value"
          placeholder="Select State"
          :options="stateOptions"
          @input="stateChange"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!job.state" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group label="Country" label-for="country" label-class="form-label" class="col-md-6">
        <v-select
          id="country"
          class="form-control v-select-custom"
          :class="{ 'is-invalid': !$v.job.country.required && $v.job.country.$dirty }"
          label="text"
          disabled
          v-model="job.country"
          :reduce="country => country.value"
          placeholder="Select country"
          :options="countryOptions"
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!job.country" v-bind="attributes" v-on="events" />
          </template>
        </v-select>
        <b-form-invalid-feedback :state="!$v.job.country.required && $v.job.country.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>
    </div>

    <!-- Pay/Salary details -->
    <div class="row">
      <b-form-group label="Minimum Pay" label-for="minimum_pay" label-class="form-label" class="col-md-6">
        <currency-input
          v-model="job.minimum_pay"
          required
          class="form-control"
          placeholder="Enter minimum pay"
          :allow-negative="false"
        />
        <!-- :class="{ 'is-invalid': !$v.job.minimum_pay.required && $v.job.minimum_pay.$dirty }"
        <b-form-invalid-feedback :state="!$v.job.minimum_pay.required && $v.job.minimum_pay.$dirty ? false : null"
          >This field is required.</b-form-invalid-feedback
        > -->
      </b-form-group>

      <b-form-group label="Maximum Pay" label-for="maximum_pay" label-class="form-label" class="col-md-6">
        <currency-input
          v-model="job.maximum_pay"
          class="form-control"
          placeholder="Enter maximum pay (optional)"
          :allow-negative="false"
          :value-range="{ ...(job.minimum_pay && { min: job.minimum_pay }) }"
        />
      </b-form-group>
    </div>

    <b-form-group label="Pay Rate" label-for="pay_rate" label-class="form-label">
      <v-select
        id="pay_rate"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.pay_rate.required && $v.job.pay_rate.$dirty }"
        label="text"
        v-model="job.pay_rate"
        :reduce="rate => rate.value"
        placeholder="Select pay rate"
        :options="payRateOptions"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!job.pay_rate" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.job.pay_rate.required && $v.job.pay_rate.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Number of Hires" label-for="no_of_hires" label-class="form-label">
      <currency-input
        v-model="job.no_of_hires"
        class="form-control"
        :class="{ 'is-invalid': !$v.job.no_of_hires.required && $v.job.no_of_hires.$dirty }"
        placeholder="Enter number of people to hire"
        :currency="null"
        :precision="0"
        :allow-negative="false"
        required
      />
      <b-form-invalid-feedback :state="!$v.job.no_of_hires.required && $v.job.no_of_hires.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
  </b-form>
</template>

<script>
import { countries } from 'countries-list';
import { map, pick, head } from 'lodash';
import Editor from '@tinymce/tinymce-vue';
import { required, url, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import {
  USER_ROLES,
  JOB_SCHEDULE_OPTIONS,
  JOB_TYPE_OPTIONS,
  JOB_TYPES,
  USER_ROLE_TYPES
} from '../../../common/constants';
var UsaStates = require('usa-states').UsaStates;
export default {
  name: 'JobBasicInfoForm',
  components: { Editor },

  props: {
    jobData: { type: Object, required: true }
  },

  data() {
    return {
      USER_ROLES,
      USER_ROLE_TYPES,
      JOB_TYPES,
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,

      job: {
        job_type: JOB_TYPES.LOCAL,
        title: '',
        description: '',
        is_active: true,
        type: '',
        schedule: '',
        application_link: '',
        minimum_pay: null,
        maximum_pay: null,
        pay_rate: null,
        no_of_hires: null,
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: 'United States'
      },
      allUniversities: [],
      firstLoad: true,
      countryOptions: [],
      stateOptions: [],
      typeOptions: JOB_TYPE_OPTIONS,
      scheduleOptions: JOB_SCHEDULE_OPTIONS,
      isOrgOptions: [
        { value: false, text: 'Employer' },
        { value: true, text: 'Organization' }
      ],
      payRateOptions: [
        { value: 'hourly', text: 'Hourly' },
        { value: 'weekly', text: 'Weekly' },
        { value: 'monthly', text: 'Monthly' },
        { value: 'annually', text: 'Annually' }
      ],
      activeOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Inactive' }
      ],
      jobTypeOptions: [
        { value: JOB_TYPES.STANDARD, text: 'Standard *nfjd*' },
        { value: JOB_TYPES.LOCAL, text: 'Local' }
      ],
      jobTypeOptionsLocal: [{ value: JOB_TYPES.LOCAL, text: 'Local' }],
      jobTypeOptionsStandard: [{ value: JOB_TYPES.STANDARD, text: 'Standard' }]
    };
  },

  validations() {
    return {
      job: {
        // employer: { required: requiredIf(() => this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN) },
        title: { required },
        description: { required },
        is_active: { required },
        type: { required },
        schedule: { required },
        application_link: { required, url },
        // org: { required: requiredIf(() => this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && this.isOrg) },
        job_type: { required: requiredIf(() => this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN) },
        minimum_pay: {},
        maximum_pay: {},
        pay_rate: { required },
        no_of_hires: { required },
        //   schools: { required: requiredIf(() => this.job.job_type === JOB_TYPES.LOCAL) },

        address: { required },
        city: { required },
        state: { required },
        zipcode: { required },
        country: { required }
        // associated_programs: { required },
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser'])
  },

  watch: {
    jobData: {
      handler() {
        if (this.firstLoad) {
          this.job = pick(this.jobData, [
            'job_type',
            'title',
            'description',
            'is_active',
            'type',
            'schedule',
            'application_link',
            'minimum_pay',
            'maximum_pay',
            'pay_rate',
            'no_of_hires',
            //    'schools',
            // 'org',
            'address',
            'city',
            'state',
            'zipcode',
            'country'
          ]);
          this.$nextTick(() => {
            this.job.associated_programs = this.jobData.associated_programs;
            this.job.universities = this.jobData.universities;
          });
          this.firstLoad = false;
        }
      },
      deep: true
    },
    'job.job_type': {
      handler(value) {
        if (value) {
          this.jobTypeChanged(value);
        }
      }
    }
  },

  methods: {
    ...mapActions('uni', ['getAllUnis']),
    head,
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.job }
      };
    },

    async fetchAllUniversities() {
      const res = await this.getAllUnis();
      this.allUniversities = res.data;
    },

    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name
        }))
      );
    },
    updateJobData(data) {
      this.job = pick(data, [
        'job_type',
        'title',
        'description',
        'is_active',
        'type',
        'schedule',
        'application_link',
        'minimum_pay',
        'maximum_pay',
        'pay_rate',
        'no_of_hires',
        'schools',
        'address',
        'city',
        'state',
        'zipcode',
        'country'
      ]);
      this.$nextTick(() => {
        this.job.associated_programs = data.associated_programs;
        this.job.universities = data.universities;
      });
    },
    stateChange(value) {
      this.$emit('citiesFetched', value);
    },
    jobTypeChanged(value) {
      if (value === JOB_TYPES.LOCAL) {
        this.job.job_type = value;
      } else if (value === JOB_TYPES.STANDARD) {
        this.job.job_type = value;
        this.job.schools = null;
      }
      this.$emit('typeChanged', value);
    }
  },

  async mounted() {
    this.fillInOptions();
  }
};
</script>
