<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
          ? `List of jobs posted by you/your associated employers.`
          : getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
          ? 'Manage jobs posted by you / your associated organization.'
          : ''
      "
    />
    <div class="page-section">
      <div :class="containerClass">
        <b-skeleton-wrapper
          :loading="isJobCreditLoading"
          v-if="
            getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER || getLoggedInUser.role === USER_ROLES.ORGANIZATION
          "
        >
          <template #loading>
            <b-skeleton class="w-25"></b-skeleton>
          </template>
          <div class="d-flex flex-row aligin-items-center">
            <i class="material-icons icon-16pt mr-1 d-flex align-self-center text-primary">work</i>
            <span class="card-title flex mr-12pt">{{ getAvailableJobCredits }} Job Posting(s) Available</span>
          </div>
        </b-skeleton-wrapper>
        <page-separator title="" />

        <div class="d-flex justify-content-center" v-if="!totalJobs && !isLoading && !searchTerm">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Jobs Posted</h4>

              <p class="text-muted">
                You haven't posted any job yet. Please click below to post a job and let the candidates know about the
                openings at your company.
              </p>

              <router-link :to="{ name: 'post-job' }" class="btn btn-primary">Post a Job</router-link>
            </div>
          </div>
        </div>
        <div class="card mb-0" v-else>
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Jobs"
                    v-model="searchTerm"
                    @input="onSearch"
                    :disabled="isLoading"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <!-- TODO: Add filters here. (if needed) -->
                <!-- <b-dropdown class="ml-lg-auto" :text="$t('All Topics')" variant="link text-black-70" right>
                  <b-dropdown-item active>All Topics</b-dropdown-item>
                  <b-dropdown-item>My Topics</b-dropdown-item>
                </b-dropdown>

                <b-dropdown class="mr-8pt" :text="$t('Newest')" variant="link text-black-70" right>
                  <b-dropdown-item active>Newest</b-dropdown-item>
                  <b-dropdown-item>Unanswered</b-dropdown-item>
                </b-dropdown> -->
                <span class="clickable-item" v-b-popover.hover.top :title="showMsg">
                  <b-btn :to="{ name: 'post-job' }" exact variant="primary" :disabled="disableJobPost"
                    >Post a Job</b-btn
                  >
                </span>
              </div>
            </div>
          </div>

          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template>

            <!-- Jobs Table -->
            <b-table
              :fields="tableColumns"
              :items="jobs"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              hover
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
              @row-clicked="editJob"
            >
              <template #cell(menu)="data">
                <div class="text-center">
                  <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                    <template #button-content>
                      <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                      <span class="sr-only">Menu</span>
                    </template>
                    <div>
                      <b-dropdown-item
                        v-if="
                          (getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION && !data.item.is_expired) ||
                          (getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER && !data.item.is_expired)
                        "
                        href="#"
                        @click.prevent="toggleJobStatus(data.index)"
                        :title="data.item.is_active ? 'Mark Inactive' : 'Mark Active'"
                      >
                        <md-icon :class="data.item.is_active ? 'text-danger' : 'text-success'">{{
                          data.item.is_active ? 'block' : 'check_circle_outline'
                        }}</md-icon>
                        {{ data.item.is_active ? 'Mark Inactive' : 'Mark Active' }}
                      </b-dropdown-item>
                    </div>

                    <div>
                      <b-dropdown-item
                        v-if="
                          getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN ||
                          (getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION && !data.item.is_expired) ||
                          (getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER && !data.item.is_expired)
                        "
                        :to="{
                          name: 'edit-job',
                          params: { id: data.item.id },
                        }"
                      >
                        <md-icon class="text-info">edit</md-icon>
                        Edit</b-dropdown-item
                      >
                    </div>

                    <b-dropdown-item href="#" @click.prevent="confirmDeleteJob(data.item.id)">
                      <md-icon class="text-danger">delete</md-icon>
                      Delete
                    </b-dropdown-item>
                    <div>
                      <b-dropdown-item href="#" @click.prevent="cloneJob(data.item.id)">
                        <md-icon class="text-secondary">content_copy</md-icon>
                        Clone Job
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>
              <template #cell(name)="data">
                <strong> {{ data.item.name }} </strong>
              </template>
              <template #cell(title)="data">
                <span class="d-flex align-items-end">
                  <md-icon
                    class="mr-1 text-info"
                    v-b-popover.hover.left
                    title="Organization"
                    v-if="
                      (getLoggedInUser.role === USER_ROLES.EMPLOYER_ADMIN && data.item.ext_posted_by) ||
                      (getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && data.item.ext_posted_by)
                    "
                    >business_center</md-icon
                  ><md-icon
                    class="mr-1 text-info"
                    v-b-popover.hover.left
                    title="Employer"
                    v-if="
                      (getLoggedInUser.role === USER_ROLES.ORGANIZATION && !data.item.ext_posted_by) ||
                      (getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && !data.item.ext_posted_by)
                    "
                    >business</md-icon
                  >{{ data.item.title }}
                </span>
              </template>

              <template #cell(logo)="data">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside">
                  <b-img
                    :src="
                      data.item.job_type === JOB_TYPES.EXTERNAL
                        ? data.item.extra.logo
                        : data.item.employer && data.item.employer.logo_url
                    "
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="
                      data.item.job_type === JOB_TYPES.EXTERNAL
                        ? data.item.extra.logo
                        : data.item.employer && data.item.employer.logo_url
                    "
                  />
                  <md-icon v-else>business</md-icon>
                </fmv-avatar>
              </template>

              <template #cell(employer)="data">
                {{
                  data.item.job_type === JOB_TYPES.EXTERNAL
                    ? data.item.extra.company
                    : data.item.employer && data.item.employer.name
                }}
              </template>

              <template #cell(posted_for)="data">
                {{ data.item.employer && data.item.employer.name }}
              </template>

              <template #cell(ext_posted_by)="data">
                <span v-if="data.item.ext_posted_by"> {{ get(data.item.ext_posted_by, 'name') }}</span>
                <span v-else>{{ data.item.employer.name }}</span>
              </template>
              <template #cell(job_type)="data">
                {{ $t(`jobPostingTypes.${data.item.job_type}`) }}
                <i class="material-icons icon-16pt ml-1 text-black-50" :id="`scl-popover-${data.item.id}`">info</i>
                <!-- v-if="data.item.job_type === JOB_TYPES.LOCAL" -->
                <school-list-popover
                  :schools="data.item.schools"
                  :target-id="data.item.id"
                  :standardJob="data.item.job_type !== JOB_TYPES.LOCAL"
                ></school-list-popover>
                <!-- v-if="data.item.job_type === JOB_TYPES.LOCAL" -->
              </template>

              <template #cell(address)="data">
                {{ data.item.address }}, {{ data.item.zipcode }}, {{ data.item.city }}, {{ data.item.state }},
                {{ data.item.country }}
              </template>

              <template #cell(type)="data">
                {{ data.item.type ? $t(`jobWorkTypeOpts.${data.item.type}`) : '-' }}
              </template>

              <template #cell(status)="data">
                <b-badge
                  :class="
                    'border border-' +
                    (data.item.is_expired
                      ? `secondary text-secondary`
                      : data.item.is_active
                      ? `success text-success`
                      : `danger text-danger`)
                  "
                  variant="none"
                  >{{ data.item.is_expired ? 'Expired' : data.item.is_active ? 'Active' : 'Inactive' }}</b-badge
                >
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalJobs"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="jobs-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import Pagination from '../../components/Ui/Pagination.vue';
import MdIcon from '../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLES, JOB_TYPES, USER_ROLE_TYPES } from '../../common/constants';
import SchoolListPopover from './PostJob/SchoolListPopover.vue';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination, FmvAvatar, SchoolListPopover },
  extends: Page,

  name: 'Jobs',

  data() {
    return {
      title: 'Jobs',
      JOB_TYPES,

      isLoading: true,
      isJobCreditLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalJobs: 0,
      jobs: [],
      ordering: '-is_active,-id',

      isJobCloning: false,
      openInfoModal: false,
      USER_ROLE_TYPES,
      USER_ROLES,
      searchTerm: '',
      jobCredits: null,
      empOrgCredits: null,
      isEmpOrgCreditLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('credit', ['getAvailableTokens', 'getAvailableJobCredits']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Jobs', active: true },
      ];
    },
    calculateCredits() {
      return 1 - this.getAvailableJobCredits;
    },

    showMsg() {
      const message =
        get(this.getLoggedInUser.linked_entity, 'organization') &&
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
        !this.getAvailableJobCredits &&
        get(this.empOrgCredits, 'allow_emp_deduct') &&
        get(this.empOrgCredits, 'amount_available')
          ? `You have insufficient job credits.You will be using ${this.calculateCredits} credit/s of your organization.`
          : (this.getLoggedInUser.role === USER_ROLES.ORGANIZATION ||
              this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) &&
            !this.getAvailableJobCredits
          ? 'You have 0 job posting credits remaining. Please purchase more to post a job to partnered schools.'
          : '';

      return message;
    },
    disableJobPost() {
      return (
        (this.getLoggedInUser.role === USER_ROLES.ORGANIZATION && !this.getAvailableJobCredits) ||
        (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
          !this.getAvailableJobCredits &&
          ((get(this.empOrgCredits, 'allow_emp_deduct') && !get(this.empOrgCredits, 'amount_available')) ||
            !get(this.empOrgCredits, 'allow_emp_deduct'))) ||
        this.isEmpOrgCreditLoading
      );
    },

    tableColumns() {
      let columns = [
        {
          key: 'menu',
          label: '',
          tdClass: 'text-left',
        },
      ];
      if (this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN) {
        columns = [
          {
            key: 'menu',
            label: '',
            tdClass: 'text-left',
          },
          { key: 'logo', label: '', tdClass: 'clickable-item' },
          { key: 'employer', label: 'Employer', tdClass: 'clickable-item' },
        ];
      }
      return columns.concat([
        { key: 'id', label: 'Job ID', tdClass: 'clickable-item' },
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },
        { key: 'status', label: this.$t('generalMsgs.status'), tdClass: 'clickable-item' },
        { key: 'job_type', label: 'Job Type', tdClass: 'clickable-item' },
        ...(this.getLoggedInUser.role === USER_ROLES.ORGANIZATION
          ? [{ key: 'posted_for', label: 'Posted For', tdClass: 'clickable-item' }]
          : []),

        ...(this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN
          ? [{ key: 'ext_posted_by', label: 'Posted By', tdClass: 'clickable-item' }]
          : []),
        {
          key: 'views_count',
          label: 'Clicks',
          formatter: (value) => {
            return this.$n(value || 0);
          },
          tdClass: 'clickable-item',
        },
        { key: 'type', label: 'Type', tdClass: 'clickable-item' },
        {
          key: 'created_at',
          label: 'Posted On',
          formatter: (value) => {
            return moment(value).format('HH:mm, MM/DD/YYYY');
          },
          tdClass: 'clickable-item',
        },
      ]);
    },
  },

  methods: {
    ...mapActions('job', ['getAllJobs', 'updateJob', 'deleteJob', 'createCloneJob']),
    ...mapActions('organization', ['getEmpOrgCredits']),
    ...mapActions('credit', ['updateStateAvailTokens']),
    showCloneJobMsg(id) {
      const message =
        get(this.getLoggedInUser.linked_entity, 'organization') &&
        this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
        !this.getAvailableJobCredits &&
        get(this.empOrgCredits, 'allow_emp_deduct') &&
        get(this.empOrgCredits, 'amount_available')
          ? `You have insufficient job credits.You will be using ${this.calculateCredits} credit/s of your organization to clone the job.`
          : `1 job credit will be deducted from your wallet. Please confirm that you want to duplicate job #${id}.`;

      return message;
    },
    async fetchJobCredits() {
      this.isJobCreditLoading = true;
      await this.updateStateAvailTokens();
      this.isJobCreditLoading = false;
    },
    async fetchEmpOrgCredits() {
      this.isEmpOrgCreditLoading = true;
      const res = await this.getEmpOrgCredits({ credits_type: 'job_credits' });
      this.empOrgCredits = res.data;
      this.isEmpOrgCreditLoading = false;
    },

    get,
    async cloneJob(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(this.showCloneJobMsg(id), {
          title: 'Clone Job',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'success',
          okTitle: 'Yes',
          cancelTitle: 'No',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        });
        if (isConfirmed) {
          this.isJobCloning = true;
          await this.createCloneJob({ job_id: id });
          this.fetchJobs();
          this.fetchJobCredits();
          this.makeToast({ variant: 'success', msg: 'Job Cloned!' });
        }
      } catch (err) {
        if (get(err, 'response.status') === 400 && get(err, 'response.data.message') === 'no credits') {
          this.makeToast({
            variant: 'danger',
            msg: 'You do not have sufficient job credits to clone this job. Please recharge and try again!s',
          });
        } else {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }

      this.isJobCloning = false;
    },
    async fetchJobs(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllJobs({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
      });
      this.jobs = response.data.results;
      this.currentPage = pageNum;
      this.totalJobs = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchJobs(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchJobs();
    },

    onSearch() {
      this.debouncedSearchJobs(this);
    },

    debouncedSearchJobs: debounce((vm) => {
      vm.fetchJobs();
    }, 500),

    async toggleJobStatus(index) {
      const currStatus = this.jobs[index].is_active;
      this.jobs[index].is_active = !currStatus;

      await this.updateJob({ id: this.jobs[index].id, data: { is_active: !currStatus } });
      this.makeToast({ variant: 'success', msg: `Job marked as ${currStatus ? 'Inactive' : 'Active'}` });
    },

    async confirmDeleteJob(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected job. You will not be able to access any related data after removal. If the job is no longer active, you can simply change the status to 'Inactive'.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.deleteJob(id);
          const refreshPage = this.jobs.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchJobs(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Job Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },

    editJob(row) {
      // if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
      this.$router.push({ name: 'manage-job-public-view', params: { id: row.id, backTo: true } });
      //  } else this.$router.push({ name: 'edit-job', params: { id: row.id } });
    },
  },

  async mounted() {
    if (
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER ||
      this.getLoggedInUser.role === USER_ROLES.ORGANIZATION
    ) {
      await this.fetchJobCredits();
    }
    if (
      this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER &&
      this.getLoggedInUser.linked_entity.organization
    ) {
      this.fetchEmpOrgCredits();
    }
    this.fetchJobs();
  },
};
</script>
