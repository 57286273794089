<template>
  <div>
    <page-header
      :title="title"
      :container-class="containerClass"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
          ? `List of simulations posted by you/your associated employers.`
          : getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
          ? 'Manage simulations posted by you / your associated organization.'
          : ''
      "
    />

    <div class="page-section">
      <div :class="containerClass">
        <page-separator title="" />
        <div class="d-flex justify-content-center" v-if="!totalSimulations && !isLoading && !searchTerm">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Simulations Posted</h4>

              <p class="text-muted">
                You haven't posted any simulations yet. Please click below to post a simulations.
              </p>

              <router-link :to="{ name: 'post-simulation' }" class="btn btn-primary">Post Simulation</router-link>
            </div>
          </div>
        </div>
        <div class="card mb-0" v-else>
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Simulations"
                    v-model="searchTerm"
                    @input="onSearch"
                    :disabled="isLoading"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <!-- Filters and Add Btn -->
              <div class="col-lg d-flex flex-wrap justify-content-end">
                <span class="clickable-item" v-b-popover.hover.top title="">
                  <b-btn :to="{ name: 'post-simulation' }" exact variant="primary">Post Simulation</b-btn>
                </span>
              </div>
            </div>
          </div>

          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template>

            <!-- Simulations Table -->
            <b-table
              :fields="tableColumns"
              :items="simulations"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              hover
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
              @row-clicked="editSimulation"
            >
              <template #cell(menu)="data">
                <div class="text-center">
                  <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                    <template #button-content>
                      <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                      <span class="sr-only">Menu</span>
                    </template>
                    <div>
                      <b-dropdown-item
                        v-if="
                          getLoggedInUser.role !== USER_ROLES.EMPLOYER ||
                            data.item.posted_by.entity_type !== 'organization'
                        "
                        href="#"
                        @click.prevent="toggleSimulationStatus(data.index)"
                        :title="data.item.is_active ? 'Mark Inactive' : 'Mark Active'"
                      >
                        <md-icon :class="data.item.is_active ? 'text-danger' : 'text-success'">{{
                          data.item.is_active ? 'block' : 'check_circle_outline'
                        }}</md-icon>
                        {{ data.item.is_active ? 'Mark Inactive' : 'Mark Active' }}
                      </b-dropdown-item>

                      <b-dropdown-item href="#" @click.prevent="copyPreviewLink(data.item)">
                        <md-icon :class="'text-info'">content_copy</md-icon>
                        Copy link
                      </b-dropdown-item>
                    </div>

                    <div>
                      <b-dropdown-item
                        v-if="
                          getLoggedInUser.role !== USER_ROLES.EMPLOYER ||
                            data.item.posted_by.entity_type !== 'organization'
                        "
                        :to="{
                          name: 'edit-simulation',
                          params: { id: data.item.id }
                        }"
                      >
                        <md-icon class="text-info">edit</md-icon>
                        Edit</b-dropdown-item
                      >
                    </div>
                  </b-dropdown>
                </div>
              </template>

              <template #cell(title)="data">
                {{ data.item.title }}
              </template>

              <template #cell(type)="data">
                {{ data.item.type ? $t(`simulationTypes.${data.item.type}`) : '-' }}
              </template>

              <template #cell(status)="data">
                <b-badge
                  :class="'border border-' + (data.item.is_active ? `success text-success` : `danger text-danger`)"
                  variant="none"
                  >{{ data.item.is_active ? 'Active' : 'Inactive' }}</b-badge
                >
              </template>

              <template #cell(featured)="data">
                <div class="text-center">
                  {{ data.item.is_featured ? 'Yes' : '-' }}
                </div>
              </template>

              <template #cell(posted_by)="data">
                <span class="d-flex align-items-end">
                  <md-icon
                    class="mr-1 text-info"
                    v-b-popover.hover.left
                    title="Organization"
                    v-if="data.item.posted_by.entity_type === 'organization'"
                    >business_center</md-icon
                  ><md-icon v-else class="mr-1 text-info" v-b-popover.hover.left title="Employer">business</md-icon
                  >{{ data.item.posted_by.name }}
                </span>
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalSimulations"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="simulations-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/Ui/PageHeader.vue';
import Page from '@/components/Page.vue';
import Pagination from '../../components/Ui/Pagination.vue';
import MdIcon from '../../components/Ui/MdIcon.vue';
import { DEFAULT_PAGE_SIZE, USER_ROLE_TYPES, USER_ROLES } from '../../common/constants';

export default {
  components: { MdIcon, PageHeader, PageSeparator, Pagination },
  extends: Page,

  name: 'Simulations',

  data() {
    return {
      title: 'Simulations',

      isLoading: true,
      isSimulationCreditLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalSimulations: 0,
      simulations: [],
      ordering: '-is_active,-id',
      USER_ROLE_TYPES,
      USER_ROLES,
      searchTerm: '',
      isPreviewLinkCopied: false
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Simulations', active: true }
      ];
    },

    tableColumns() {
      return [
        {
          key: 'menu',
          label: '',
          tdClass: 'text-left'
        },
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },
        { key: 'type', label: 'Type', tdClass: 'clickable-item' },
        ...(this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN
          ? [
              { key: 'featured', label: 'Featured', tdClass: 'clickable-item' },
              { key: 'posted_by', label: 'Posted By', tdClass: 'clickable-item' }
            ]
          : []),

        { key: 'status', label: this.$t('generalMsgs.status'), tdClass: 'clickable-item' },
        {
          key: 'clicks',
          label: 'Clicks',
          formatter: value => {
            return this.$n(value || 0);
          },
          tdClass: 'clickable-item'
        },
        {
          key: 'impressions',
          label: 'Impressions',
          formatter: value => {
            return this.$n(value || 0);
          },
          tdClass: 'clickable-item'
        }
      ];
    }
  },

  methods: {
    ...mapActions('simulations', ['getAllSimulations', 'updateSimulationStatus']),

    get,
    async fetchSimulations(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllSimulations({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params
      });
      this.simulations = response.data.results;
      this.currentPage = pageNum;
      this.totalSimulations = response.data.count;
      this.isLoading = false;
    },

    async copyPreviewLink(data) {
      try {
        const url = `${process.env.VUE_APP_JOIN_TRANSITION}/simulations/${data.slug}`;
        await navigator.clipboard.writeText(url);
        this.isPreviewLinkCopied = true;
        this.makeToast({ variant: 'success', msg: 'Url Copied!' });

        setTimeout(() => (this.isPreviewLinkCopied = false), 2000);
      } catch ($e) {
        this.makeToast({ variant: 'danger', msg: 'Unable to copy.' });
      }
    },

    onPageChange(pageNum) {
      this.fetchSimulations(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchSimulations();
    },

    onSearch() {
      this.debouncedSearchSimulations(this);
    },

    debouncedSearchSimulations: debounce(vm => {
      vm.fetchSimulations();
    }, 500),

    async toggleSimulationStatus(index) {
      const currStatus = this.simulations[index].is_active;
      this.simulations[index].is_active = !currStatus;

      await this.updateSimulationStatus({ id: this.simulations[index].id, data: { is_active: !currStatus } });
      this.makeToast({ variant: 'success', msg: `Simulation marked as ${currStatus ? 'Inactive' : 'Active'}` });
    },

    editSimulation(row) {
      this.$router.push({ name: 'edit-simulation', params: { id: row.id, backTo: true } });
    }
  },

  async mounted() {
    this.fetchSimulations();
  }
};
</script>
