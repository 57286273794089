<template>
  <b-popover
    v-if="targetId"
    ref="popover"
    :target="`scl-popover-${targetId}`"
    triggers="hover"
    :placement="placement"
    :html="true"
    custom-class="popover-lg"
  >
    <div v-if="!standardJob">
      <div v-for="(scl, index) in schools" :key="scl.id" :class="{ 'mb-3': index < schools.length - 1 }">
        <div class="avatar avatar-sm">
          <img :src="scl.logo_url || DefaultAvatar" alt="Avatar" class="avatar-img" />
        </div>
        <strong class="ml-2">{{ scl.name }}</strong>
      </div>
    </div>
    <div v-else>
      <strong> All Transition Schools </strong>
    </div>
  </b-popover>
</template>

<script>
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';

export default {
  name: 'SchoolListPopover',
  components: {},

  props: {
    schools: { type: Array, default: () => [] },
    targetId: { required: true },
    placement: { type: String, default: 'right' },
    standardJob: { type: Boolean, default: false },
  },

  data() {
    return {
      DefaultAvatar,
    };
  },

  computed: {
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Sample Page', active: true },
      ];
    },
  },

  methods: {},

  async mounted() {},
};
</script>
