<template>
  <div :class="containerClass">
    <page-header :title="$route.params.id ? 'Edit Simulation' : 'Post Simulation'" :container-class="null" />

    <div class="page-section">
      <div class="card">
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          shape="tab"
          :color="APP_DARK_COLOR"
          ref="jobForm"
          class="tab-fwizard"
        >
          <tab-content title="General" icon="fas fa-info-circle" :before-change="() => onNext('basicInfoForm')">
            <simulation-basic-form :simulationData="simulation" ref="basicInfoForm"></simulation-basic-form>
          </tab-content>
          <tab-content :title="'Target Audience'" icon="fas fa-user" :before-change="() => onNext('audienceForm')">
            <simulation-audience-form
              :is-org-data="isOrg"
              :simulationData="simulation"
              :are-employers-loading="areEmployersLoading"
              :all-employers="allEmployers"
              :all-programs="allPrograms"
              :are-programs-loading="areProgramsLoading"
              :are-orgs-loading="areOrgsLoading"
              :all-orgs="allOrgs"
              :are-schools-loading="areSchoolsLoading"
              :all-schools="allSchools"
              @orgChanged="changeSelectedOrg"
              @employerChanged="changeSelectedEmployer"
              @orgsFetched="fetchAllOrgs"
              @schoolChanged="changeSelectedSchool"
              ref="audienceForm"
            ></simulation-audience-form>
          </tab-content>

          <tab-content
            :title="'Questions'"
            icon="fas fa-hand-holding-medical"
            :before-change="() => onNext('questionsForm')"
          >
            <simulation-questions-form :simulationData="simulation" ref="questionsForm"></simulation-questions-form>
          </tab-content>

          <tab-content
            title="Conversation Style"
            icon="fas fa-graduation-cap"
            :before-change="() => onNext('conversationForm')"
          >
            <simulation-conversation-form
              :simulationData="simulation"
              ref="conversationForm"
            ></simulation-conversation-form>
          </tab-content>

          <template v-slot:prev>
            <button class="btn btn-dark btn-normal" type="dark" :disabled="isFormLoading" style="min-width: 120px">
              Back
            </button>
          </template>

          <template v-slot:next>
            <b-button
              type="submit"
              variant="dark"
              :disabled="isFormLoading"
              style="min-width: 120px"
              class="btn-normal"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Next</span>
            </b-button>
          </template>

          <template v-slot:finish>
            <button
              class="btn btn-primary btn-normal"
              type="primary"
              :disabled="isFormLoading"
              style="min-width: 120px"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>{{ $route.params.id ? 'Update' : 'Post Simulation' }}</span>
            </button>
          </template>

          <template #step="props" v-if="isFormLoading">
            <wizard-step :tab="props.tab" :transition="props.transition" :index="props.index" />
          </template>
        </form-wizard>
      </div>
    </div>
  </div>
</template>

<script>
import { get, pick } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '../../../components/Page.vue';
import PageHeader from '../../../components/Ui/PageHeader.vue';
import SimulationBasicForm from './SimulationBasicForm.vue';
import SimulationQuestionsForm from './SimulationQuestionsForm.vue';
import SimulationConversationForm from './SimulationConversationForm.vue';
import SimulationAudienceForm from './SimulationAudienceForm.vue';
import { APP_DARK_COLOR, USER_ROLES } from '../../../common/constants';

export default {
  components: {
    PageHeader,
    SimulationBasicForm,
    SimulationQuestionsForm,
    SimulationConversationForm,
    SimulationAudienceForm
  },
  extends: Page,

  data() {
    return {
      APP_DARK_COLOR,
      title: this.$route.params.id ? 'Edit Simulation' : 'Post Simulation',
      isOrg: false,
      simulation: {
        simulation_type: '',
        title: '',
        image: '',
        instructions: '',
        details: '',
        schools: null,
        employer: null,
        org: null,
        associated_programs: null,
        questionFlow: null,
        questions: '',
        voice: '',
        closing: '',
        greetings: '',
        personality: '',
        is_featured: false,
        universities: []
      },
      allEmployers: [],
      allSchools: [],
      allPrograms: [],
      allOrgs: [],
      areOrgsLoading: false,
      isLoading: false,
      areEmployersLoading: false,
      areSchoolsLoading: false,
      areProgramsLoading: false,
      USER_ROLES
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.areProgramsLoading || this.areEmployersLoading || this.areSchoolsLoading;
    }
  },

  methods: {
    ...mapActions('simulations', ['getSimulation', 'createSimulation', 'updateSimulation']),
    ...mapActions('employer', ['getAllEmployers']),
    ...mapActions('partnership', ['getAllPartneredSchools']),
    ...mapActions('program', ['getPublicSchoolPrograms', 'getAllPrograms']),
    ...mapActions('organization', ['getAllOrganizations']),
    ...mapActions('school', ['getAllSchools']),
    async fetchPrograms() {
      this.areProgramsLoading = true;

      try {
        if (this.isOrg) {
          const response = await this.getAllPrograms({ ordering: 'id' });
          this.allPrograms = response.data;

          if (!this.allPrograms.length) {
            this.allPrograms.push({ title: 'Other', id: 'other' });
          }
        } else {
          const response = await this.getPublicSchoolPrograms({ school: this.simulation.schools });
          this.allPrograms = response.data;

          if (!this.allPrograms.length) {
            this.allPrograms.push({ title: 'Other', id: 'other' });
          }
        }
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areProgramsLoading = false;
    },
    fetchAllOrgs(value) {
      this.simulation.org = null;
      this.simulation.employer = null;
      this.simulation.schools = null;
      this.simulation.associated_programs = [];
      this.allSchools = [];
      this.allEmployers = [];
      if (value) {
        this.isOrg = true;
        this.fetchOrgs();
        this.fetchSchools();
        this.fetchPrograms();
      } else {
        this.isOrg = false;
        this.fetchEmployers();
      }
    },
    changeSelectedOrg(value) {
      if (value) {
        this.simulation.org = value;
        this.fetchEmployers(value);
      } else {
        this.simulation.org = null;
        this.allSchools = [];
        this.allEmployers = [];
        this.allPrograms = [];
      }
    },
    async fetchOrgs() {
      this.areOrgsLoading = true;
      try {
        const response = await this.getAllOrganizations({});
        this.allOrgs = response.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.areOrgsLoading = false;
    },

    onNext(formRef) {
      let dataValidation = { isValid: false, formData: {} };
      if (this.$refs[formRef]) {
        dataValidation = this.$refs[formRef].validateFormData();
        this.simulation = { ...this.simulation, ...dataValidation.formData };
      }
      if (!dataValidation.isValid) {
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      }
      return dataValidation.isValid;
    },

    async onComplete() {
      this.isLoading = true;

      try {
        const data = {
          title: this.simulation.title,
          is_featured: this.simulation.is_featured,
          image_url: this.simulation.image,
          instructions: this.simulation.instructions,
          details: this.simulation.details,
          posted_by_id:
            this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN
              ? this.isOrg
                ? this.simulation.org
                : this.simulation.employer
              : this.getLoggedInUser.role === USER_ROLES.EMPLOYER_ADMIN
              ? this.getLoggedInUser.linked_entity.id
              : this.getLoggedInUser.linked_entity.id,
          posted_by_type:
            this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN
              ? this.isOrg
                ? 'organization'
                : 'employer'
              : this.getLoggedInUser.role === USER_ROLES.EMPLOYER_ADMIN
              ? 'employer'
              : 'organization',
          question_flow: this.simulation.questionFlow,
          questions: this.simulation.questions,
          personality_type: this.simulation.personality,
          greeting: this.simulation.greetings,
          closing: this.simulation.closing,
          type: this.simulation.simulation_type,
          voice_id: this.simulation.voice,
          employers: !this.simulation.employer
            ? []
            : (this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && this.isOrg) ||
              this.getLoggedInUser.role === USER_ROLES.ORGANIZATION
            ? typeof this.simulation.employer === 'number'
              ? [this.simulation.employer]
              : this.simulation.employer
            : [],
          schools: !this.simulation.schools
            ? []
            : typeof this.simulation.schools === 'number'
            ? [this.simulation.schools]
            : this.simulation.schools,
          programs:
            get(this.simulation.associated_programs[0], 'id') === 'other' ||
            this.simulation.associated_programs === 'other'
              ? []
              : this.simulation.associated_programs,

          universities: this.simulation.universities
        };

        if (this.$route.params.id) {
          await this.updateSimulation({
            id: this.$route.params.id,
            data
          });
          this.makeToast({ variant: 'success', msg: 'Simulation Updated' });
        } else {
          await this.createSimulation(data);
          this.makeToast({ variant: 'success', msg: 'Simulation Posted' });
        }
        setTimeout(() => this.$router.push({ name: 'simulations-management-list' }), 250);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    changeSelectedEmployer(employer_id) {
      if (!this.isOrg) {
        this.simulation.schools = null;
        this.allSchools = [];
        if (employer_id) {
          this.fetchSchools(employer_id);
        } else {
          this.simulation.employer = null;
        }
      }
    },
    changeSelectedSchool(obj) {
      if (!this.isOrg) {
        if (obj.value) {
          this.simulation.employer = obj.employer;
          this.simulation.schools = obj.value;

          this.areProgramsLoading = true;
          const school = this.allSchools.find(school => school.id === obj.value);
          this.allPrograms = get(school, 'partnership_programs', []);

          if (!this.allPrograms.length) {
            this.allPrograms.push({ title: 'Other', id: 'other' });
          }

          this.areProgramsLoading = false;
        } else {
          this.simulation.associated_programs = [];
          this.allPrograms = [];
          this.simulation.schools = null;
        }
      }
    },

    async fetchEmployers(org_id = null) {
      this.areEmployersLoading = true;
      let resp;
      if (org_id) {
        resp = await this.getAllEmployers({
          organization__id: org_id
        });
      } else {
        resp = await this.getAllEmployers({});
      }
      this.allEmployers = resp.data;

      this.areEmployersLoading = false;
    },
    async fetchSchools(employer_id = null) {
      this.areSchoolsLoading = true;
      this.allSchools = [];
      if (this.isOrg) {
        const response = await this.getAllSchools({});

        this.allSchools = response.data;
      } else if (!this.isOrg) {
        const response = await this.getAllPartneredSchools({ employer: employer_id });

        this.allSchools = response.data;
      } else {
        const response = await this.getAllPartneredSchools({});

        this.allSchools = response.data;
      }
      this.areSchoolsLoading = false;
    },
    async fetchSimulation() {
      this.isLoading = true;

      try {
        const resp = (await this.getSimulation(this.$route.params.id)).data;
        this.simulation = {
          ...pick(resp, ['title', 'instructions', 'details', 'questions', 'closing', 'is_featured'])
        };

        this.simulation.image = get(resp, 'image_url');
        this.simulation.questionFlow = get(resp, 'question_flow');
        this.simulation.personality = get(resp, 'personality_type');
        this.simulation.greetings = get(resp, 'greeting');
        this.simulation.simulation_type = get(resp, 'type');
        this.simulation.voice = get(resp, 'agent.voice_id');
        this.simulation.schools =
          resp.posted_by.entity_type === 'organization' ? resp.schools.map(emp => emp.id) : resp.schools[0].id;
        this.simulation.associated_programs = resp.programs.length
          ? resp.programs.map(program => program.id)
          : [{ title: 'Other', id: 'other' }];

        this.simulation.employer =
          resp.posted_by.entity_type === 'organization' ? resp.employers.map(emp => emp.id) : get(resp, 'posted_by_id');

        this.simulation.org = resp.posted_by.entity_type === 'organization' ? get(resp, 'posted_by_id') : null;
        this.isOrg = resp.posted_by.entity_type === 'organization' ? true : false;

        this.simulation.universities = resp.universities;

        this.areProgramsLoading = true;

        if (this.getLoggedInUser.role !== USER_ROLES.ORGANIZATION && this.simulation.schools) {
          await this.fetchSchools(this.simulation.employer);
          if (!this.isOrg) {
            const school = this.allSchools.find(school => school.id === this.simulation.schools);
            this.allPrograms = get(school, 'partnership_programs', []);
          }
        }
        this.areProgramsLoading = false;
      } catch (e) {
        if (this.$route.name === 'edit-simulation') {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      }
      this.isLoading = false;
    }
  },

  async mounted() {
    if (this.getLoggedInUser.role === USER_ROLES.EMPLOYER_ADMIN) {
      this.fetchSchools();
    }

    if (this.$route.params.id) {
      await this.fetchSimulation();
    }
    if (this.simulation.org) {
      this.fetchOrgs();
    }

    if (this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN) {
      this.fetchEmployers(this.simulation.org);
      this.fetchPrograms();
    } else if (this.getLoggedInUser.role === USER_ROLES.ORGANIZATION) {
      this.isOrg = true;
      this.fetchEmployers(this.simulation.org);
      this.fetchPrograms();
    }
  }
};
</script>
