<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <b-form-group
      label="Resume Required"
      label-for="resume_required"
      label-class="form-label"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="resume_required"
        v-model="job.resume_required"
        :options="yesNoOptions"
        :aria-describedby="ariaDescribedby"
        required
        :state="!$v.job.resume_required.required && $v.job.resume_required.$dirty ? false : null"
      ></b-form-radio-group>
      <b-form-invalid-feedback :state="!$v.job.resume_required.required && $v.job.resume_required.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      label="Email Contact Allowed"
      label-for="email_contact_allowed"
      label-class="form-label"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="email_contact_allowed"
        v-model="job.email_contact_allowed"
        :options="yesNoOptions"
        :aria-describedby="ariaDescribedby"
        required
        :state="!$v.job.email_contact_allowed.required && $v.job.email_contact_allowed.$dirty ? false : null"
      ></b-form-radio-group>
      <b-form-invalid-feedback
        :state="!$v.job.email_contact_allowed.required && $v.job.email_contact_allowed.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      label="Contact Email Address"
      label-for="contact_email"
      label-class="form-label"
      v-if="job.email_contact_allowed"
    >
      <b-form-input
        id="contact_email"
        placeholder="Enter contact email address."
        v-model.trim="job.contact_email"
        required
        :state="
          (!$v.job.contact_email.required || !$v.job.contact_email.email) && $v.job.contact_email.$dirty ? false : null
        "
      />
      <b-form-invalid-feedback v-if="!$v.job.contact_email.required">This field is required.</b-form-invalid-feedback>
      <b-form-invalid-feedback v-else>Invalid email address.</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group
      label="Phone Contact Allowed"
      label-for="phone_contact_allowed"
      label-class="form-label"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="phone_contact_allowed"
        v-model="job.phone_contact_allowed"
        :options="yesNoOptions"
        :aria-describedby="ariaDescribedby"
        required
        :state="!$v.job.phone_contact_allowed.required && $v.job.phone_contact_allowed.$dirty ? false : null"
      ></b-form-radio-group>
      <b-form-invalid-feedback
        :state="!$v.job.phone_contact_allowed.required && $v.job.phone_contact_allowed.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      label="Contact Phone Number"
      label-for="contact_phone"
      label-class="form-label"
      v-if="job.phone_contact_allowed"
    >
      <b-form-input
        id="contact_phone"
        placeholder="(XXX) XXX - XXXX"
        v-model.trim="job.contact_phone"
        v-mask="'(###) ### - ####'"
        required
        :state="
          (!$v.job.contact_phone.required || !$v.job.contact_phone.isCorrectFormat) && $v.job.contact_phone.$dirty
            ? false
            : null
        "
      />
      <b-form-invalid-feedback v-if="!$v.job.contact_phone.required">This field is required.</b-form-invalid-feedback>
      <b-form-invalid-feedback v-else>
        Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
      </b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required, requiredIf, email } from 'vuelidate/lib/validators';
import { pick } from 'lodash';

import { PHONE_FORMAT_REGEX } from '../../../common/constants';

export default {
  name: 'JobPreferencesForm',
  components: {},

  props: {
    jobData: { type: Object, required: true },
  },

  data() {
    return {
      job: {
        resume_required: null,
        email_contact_allowed: null,
        contact_email: null,
        phone_contact_allowed: null,
        contact_phone: null,
      },

      yesNoOptions: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
    };
  },

  validations() {
    return {
      job: {
        resume_required: { required },
        email_contact_allowed: { required },
        phone_contact_allowed: { required },
        contact_email: { required: requiredIf(() => this.job.email_contact_allowed), email },
        contact_phone: {
          required: requiredIf(() => this.job.phone_contact_allowed),
          isCorrectFormat(value) {
            return !this.job.phone_contact_allowed || PHONE_FORMAT_REGEX.test(value);
          },
        },
      },
    };
  },

  computed: {},

  watch: {
    jobData: {
      handler() {
        this.job = pick(this.jobData, [
          'resume_required',
          'email_contact_allowed',
          'contact_email',
          'phone_contact_allowed',
          'contact_phone',
        ]);
      },
      deep: true,
    },
  },

  methods: {
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.job },
      };
    },
  },

  async mounted() {},
};
</script>
