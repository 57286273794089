<template>
  <div :class="containerClass">
    <page-header
      :title="$route.params.id ? 'Edit Job' : 'Post Job for 30 days'"
      :container-class="null"
      :button-label="$route.params.id ? 'Show Public View' : undefined"
      :button-path="$route.params.id ? { name: 'manage-job-public-view', params: { id: $route.params.id } } : undefined"
      button-variant="secondary"
    />

    <div class="page-section">
      <div class="card">
        <!-- <div class="card-header"></div> -->
        <form-wizard
          title=""
          subtitle=""
          @on-complete="onComplete"
          shape="tab"
          :color="APP_DARK_COLOR"
          ref="jobForm"
          class="tab-fwizard"
        >
          <tab-content title="Basic Info" icon="fas fa-info-circle" :before-change="() => onNext('basicInfoForm')">
            <job-basic-info-form
              :is-org-data="isOrg"
              :job-data="job"
              :are-employers-loading="areEmployersLoading"
              :all-employers="allEmployers"
              :all-programs="allPrograms"
              :are-programs-loading="areProgramsLoading"
              :are-orgs-loading="areOrgsLoading"
              :all-orgs="allOrgs"
              @orgChanged="changeSelectedOrg"
              @employerChanged="changeSelectedEmployer"
              @typeChanged="changeSelectedJobType"
              @programsFetched="fetchPrograms"
              @orgsFetched="fetchAllOrgs"
              @schoolChanged="changeSelectedSchool"
              @fetchEmps="fetchEmployers"
              @citiesFetched="fetchCities"
              ref="basicInfoForm"
            ></job-basic-info-form>
          </tab-content>
          <tab-content :title="'Target Audience'" icon="fas fa-user" :before-change="() => onNext('audienceForm')">
            <job-audience-form
              :is-org-data="isOrg"
              :job-data="job"
              :are-employers-loading="areEmployersLoading"
              :all-employers="allEmployers"
              :all-programs="allPrograms"
              :are-programs-loading="areProgramsLoading"
              :are-orgs-loading="areOrgsLoading"
              :all-orgs="allOrgs"
              :are-schools-loading="areSchoolsLoading"
              :all-schools="allSchools"
              :are-cities-loading="areCitiesLoading"
              :all-cities="allCities"
              @orgChanged="changeSelectedOrg"
              @employerChanged="changeSelectedEmployer"
              @typeChanged="changeSelectedJobType"
              @programsFetched="fetchPrograms"
              @orgsFetched="fetchAllOrgs"
              @schoolChanged="changeSelectedSchool"
              @fetchEmps="fetchEmployers"
              ref="audienceForm"
            ></job-audience-form>
          </tab-content>

          <tab-content
            :title="'Benefits & Perks'"
            icon="fas fa-hand-holding-medical"
            :before-change="() => onNext('benefitsForm')"
          >
            <job-benefits-form :job-data="job" :career-options="careerOptions" ref="benefitsForm"></job-benefits-form>
          </tab-content>

          <tab-content
            title="Qualifications"
            icon="fas fa-graduation-cap"
            :before-change="() => onNext('qualificationsForm')"
          >
            <job-qualifications-form :job-data="job" ref="qualificationsForm"></job-qualifications-form>
          </tab-content>

          <tab-content title="Preferences" icon="fas fa-tasks" :before-change="() => onNext('preferencesForm')">
            <job-preferences-form :job-data="job" ref="preferencesForm"></job-preferences-form>
          </tab-content>

          <template v-slot:prev>
            <button class="btn btn-dark btn-normal" type="dark" :disabled="isFormLoading" style="min-width: 120px">
              Back
            </button>
          </template>

          <template v-slot:next>
            <b-button
              type="submit"
              variant="dark"
              :disabled="isFormLoading"
              style="min-width: 120px"
              class="btn-normal"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>Next</span>
            </b-button>
          </template>

          <template v-slot:finish>
            <button
              class="btn btn-primary btn-normal"
              type="primary"
              :disabled="isFormLoading"
              style="min-width: 120px"
            >
              <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
              <span v-else>{{ $route.params.id ? 'Update' : 'Post Job' }}</span>
            </button>
          </template>

          <template #step="props" v-if="isFormLoading">
            <wizard-step :tab="props.tab" :transition="props.transition" :index="props.index" />
          </template>
        </form-wizard>
        <!-- <div class="card-footer"></div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { get, map, pick, head } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '../../../components/Page.vue';
import PageHeader from '../../../components/Ui/PageHeader.vue';
import JobBasicInfoForm from './JobBasicInfoForm.vue';
import JobBenefitsForm from './JobBenefitsForm.vue';
import JobQualificationsForm from './JobQualificationsForm.vue';
import JobPreferencesForm from './JobPreferencesForm.vue';
import JobAudienceForm from './JobAudienceForm.vue';
import { APP_DARK_COLOR, USER_ROLES, JOB_TYPES, USER_ROLE_TYPES } from '../../../common/constants';

export default {
  components: {
    PageHeader,
    JobBasicInfoForm,
    JobBenefitsForm,
    JobQualificationsForm,
    JobPreferencesForm,
    JobAudienceForm,
  },
  extends: Page,

  data() {
    return {
      APP_DARK_COLOR,
      title: this.$route.params.id ? 'Edit Job' : 'Post Job',
      isOrg: false,

      isOrgOptions: [
        { value: false, text: 'Employer' },
        { value: true, text: 'Organization' },
      ],
      job: {
        employer: null,
        job_type: JOB_TYPES.LOCAL,
        title: '',
        description: '',
        org: null,
        is_active: true,
        type: '',
        schedule: '',
        application_link: '',
        schools: null,
        minimum_pay: null,
        maximum_pay: null,
        pay_rate: null,
        no_of_hires: null,

        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: 'United States',

        benefits: [],
        other_benefits: null,
        offers_career_advancement: null,
        career_advancement_interest: null,
        career_offerings: [],
        other_career_offering: null,
        career_offerings_details: '',

        caregiving_experience: '',
        required_certs: [],
        other_required_certs: null,
        required_records: [],
        other_required_records: null,

        resume_required: null,
        email_contact_allowed: null,
        phone_contact_allowed: null,
        associated_programs: [],
        universities: [],
        best_school_cities: [],
      },

      careerOptions: [],
      careers: [],
      areCitiesLoading: false,
      allCities: [],
      allEmployers: [],
      allSchools: [],
      allPrograms: [],
      allOrgs: [],
      areOrgsLoading: false,
      isLoading: false,
      areCareersLoading: false,
      areEmployersLoading: false,
      areSchoolsLoading: false,
      areProgramsLoading: false,

      uploadFile: { logo: null, profileBgImage: null },
      uploadPercent: { logo: 0, profileBgImage: 0 },
      uploadCancelTokenSource: { logo: null, profileBgImage: null },
      USER_ROLE_TYPES,
      USER_ROLES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: 'Jobs', to: { name: 'jobs-management-list' } },
        {
          text: this.$route.params.id ? 'Edit Job' : 'Post Job',
          active: true,
        },
      ];
    },
    isFormLoading() {
      return (
        this.isLoading ||
        this.areCareersLoading ||
        this.areProgramsLoading ||
        this.areEmployersLoading ||
        this.areSchoolsLoading
      );
    },
  },

  methods: {
    ...mapActions('job', ['getJob', 'createJob', 'updateJob']),
    ...mapActions('careerPreference', ['getAllCareerPreferences']),
    ...mapActions('employer', ['getAllEmployers']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('partnership', ['getAllPartneredSchools']),
    ...mapActions('program', ['getAllPrograms']),
    ...mapActions('organization', ['getAllOrganizations']),
    ...mapActions('location', ['getBestSchoolCityByName']),
    async fetchPrograms() {
      this.areProgramsLoading = true;

      try {
        const response = await this.getAllPrograms({
          ...(this.job.job_type === JOB_TYPES.LOCAL && this.job.schools && { school: this.job.schools }),
        });
        this.allPrograms = response.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areProgramsLoading = false;
    },
    fetchAllOrgs(value) {
      this.job.org = null;
      this.job.employer = null;
      this.job.schools = null;
      this.job.associated_programs = [];
      this.allSchools = [];
      this.allEmployers = [];
      // this.allPrograms = [];
      if (value) {
        this.isOrg = true;
        this.fetchOrgs();
      } else {
        this.isOrg = false;
        this.fetchEmployers();
      }
    },
    changeSelectedOrg(value) {
      if (value) {
        this.job.org = value;
        this.fetchEmployers(value);
      } else {
        this.job.org = null;
        this.allSchools = [];
        this.allEmployers = [];
        this.allPrograms = [];
      }
    },
    async fetchOrgs() {
      this.areOrgsLoading = true;
      try {
        const response = await this.getAllOrganizations({});
        this.allOrgs = response.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.areOrgsLoading = false;
    },
    async fetchCities(value) {
      this.areCitiesLoading = true;
      try {
        const response = await this.getBestSchoolCityByName({ state_name: value });
        this.allCities = response.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areCitiesLoading = false;
    },
    onNext(formRef) {
      let dataValidation = { isValid: false, formData: {} };
      if (this.$refs[formRef]) {
        dataValidation = this.$refs[formRef].validateFormData();
        this.job = { ...this.job, ...dataValidation.formData };
      }
      if (!dataValidation.isValid) {
        this.makeToast({ variant: 'danger', msg: 'Please fill the form correctly.' });
      }
      return dataValidation.isValid;
    },

    async onComplete() {
      this.isLoading = true;

      try {
        const data = {
          ...this.job,
          job_type:
            // this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER && !this.$route.params.id
            //   ? JOB_TYPES.LOCAL
            //   :
            this.job.job_type,
          career_offerings: this.job.offers_career_advancement
            ? this.job.career_offerings.filter((career) => career !== 'other')
            : [],
          other_career_offering:
            this.job.offers_career_advancement && this.job.career_offerings.includes('other')
              ? this.job.other_career_offering
              : null,
          career_offerings_details: this.job.offers_career_advancement ? this.job.career_offerings_details : null,
          career_advancement_interest: this.job.offers_career_advancement ? true : this.job.career_advancement_interest,
          other_benefits: this.job.benefits.includes('other') ? this.job.other_benefits : null,
          other_required_certs: this.job.required_certs.includes('other') ? this.job.other_required_certs : null,
          other_required_records: this.job.required_records.includes('other') ? this.job.other_required_records : null,

          org: this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && this.isOrg ? this.job.org : null,
          employer:
            this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ||
            this.getLoggedInUser.role === USER_ROLES.ORGANIZATION
              ? this.job.employer
              : this.getLoggedInUser.linked_entity.id,
          contact_email: this.job.email_contact_allowed ? this.job.contact_email : null,
          contact_phone: this.job.phone_contact_allowed ? this.job.contact_phone : null,
          schools: this.job.job_type === JOB_TYPES.LOCAL ? [this.job.schools] : [],
          associated_programs:
            this.job.job_type === JOB_TYPES.LOCAL
              ? get(this.job.associated_programs[0], 'id') === 'other' || this.job.associated_programs === 'other'
                ? []
                : [this.job.associated_programs]
              : get(this.job.associated_programs[0], 'id') === 'other' || this.job.associated_programs === 'other'
              ? null
              : this.job.associated_programs,
        };

        if (this.$route.params.id) {
          await this.updateJob({
            id: this.$route.params.id,
            data,
          });
          this.makeToast({ variant: 'success', msg: 'Job Updated' });
        } else {
          await this.createJob(data);
          this.makeToast({ variant: 'success', msg: 'Job Posted' });
        }
        setTimeout(() => this.$router.push({ name: 'jobs-management-list' }), 250);
      } catch (err) {
        if (get(err, 'response.data.owner')) {
          this.errorStates.ownerEmail = false;
        }
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    changeSelectedEmployer(employer_id) {
      this.job.schools = null;
      this.allSchools = [];
      if (this.job.job_type === JOB_TYPES.LOCAL) {
        this.job.associated_programs = null;
        this.allPrograms = [];
      }
      if (employer_id) {
        const employer = this.allEmployers.find((emp) => emp.id === employer_id);
        this.job.employer = employer_id;
        this.job.contact_email = employer.main_admin.email;
        this.job.contact_phone = employer.phone;

        this.fetchSchools(employer_id);
      } else {
        this.job.employer = null;
        this.job.contact_email = this.job.contact_phone = null;
      }
    },
    changeSelectedJobType(value) {
      if (value === JOB_TYPES.LOCAL) {
        this.job.job_type = value;
        this.allPrograms = [];
      } else if (value === JOB_TYPES.STANDARD) {
        this.job.job_type = value;
        this.job.schools = null;
        this.fetchPrograms();
      }
    },
    changeSelectedSchool(obj) {
      if (obj.value) {
        this.job.employer = obj.employer;
        this.job.schools = obj.value;

        this.areProgramsLoading = true;
        const school = this.allSchools.find((school) => school.id === obj.value);
        this.allPrograms = get(school, 'partnership_programs', []);

        if (!this.allPrograms.length) {
          this.allPrograms.push({ title: 'Other', id: 'other' });
        }

        this.areProgramsLoading = false;
      } else {
        this.job.associated_programs = this.job.job_type === JOB_TYPES.STANDARD ? [] : null;
        this.allPrograms = [];
        this.job.schools = null;
      }
    },

    async fetchCareerPreferences() {
      this.areCareersLoading = true;

      const response = await this.getAllCareerPreferences({});
      this.careerOptions = this.careers = response.data;
      this.careerOptions.push({ id: 'other', name: 'Other' });

      this.areCareersLoading = false;
    },

    async fetchEmployers(org_id = null) {
      this.areEmployersLoading = true;
      let resp;
      if (org_id) {
        resp = await this.getAllEmployers({
          organization__id: org_id,
        });
      } else {
        resp = await this.getAllEmployers({});
      }
      this.allEmployers = resp.data;

      this.areEmployersLoading = false;
    },
    async fetchSchools(employer_id = null) {
      this.areSchoolsLoading = true;
      this.allSchools = [];
      if (employer_id) {
        const response = await this.getAllPartneredSchools({ employer: employer_id });

        this.allSchools = response.data;
      } else {
        const response = await this.getAllPartneredSchools({});

        this.allSchools = response.data;
      }
      this.areSchoolsLoading = false;
    },
    async fetchJobDetails() {
      this.isLoading = true;

      try {
        const resp = (await this.getJob(this.$route.params.id)).data;
        this.job = {
          ...pick(resp, [
            'title',
            'job_type',
            'description',
            'is_active',
            'type',
            'schedule',
            'application_link',
            'minimum_pay',
            'maximum_pay',
            'pay_rate',
            'no_of_hires',

            'address',
            'city',
            'state',
            'zipcode',
            'country',

            'benefits',
            'other_benefits',
            'offers_career_advancement',
            'career_advancement_interest',
            'other_career_offering',
            'career_offerings_details',

            'caregiving_experience',
            'required_certs',
            'other_required_certs',
            'required_records',
            'other_required_records',

            'resume_required',
            'email_contact_allowed',
            'contact_email',
            'phone_contact_allowed',
            'contact_phone',
            'universities',
            'best_school_cities',
          ]),

          associated_programs: null,
          schools: null,
          career_offerings: [],
          employer: null,
        };
        this.isOrg = get(resp, 'ext_posted_by_id', null) ? true : false;
        await new Promise((r) => setTimeout(r, 500));

        this.job.associated_programs =
          resp.job_type === JOB_TYPES.LOCAL
            ? resp.associated_programs.length
              ? head(map(resp.associated_programs, 'id'))
              : [{ title: 'Other', id: 'other' }]
            : resp.associated_programs.length
            ? map(resp.associated_programs, 'id')
            : [{ title: 'Other', id: 'other' }];

        this.job.employer = get(resp.employer, 'id', null);
        this.job.org = get(resp, 'ext_posted_by_id', null);
        this.job.career_offerings = map(resp.career_offerings, 'id');
        // this.job.career_offerings = resp.career_offerings;
        this.job.schools = head(map(resp.schools, 'id'));
        if (this.job.other_career_offering) {
          this.job.career_offerings.push('other');
        }
        this.$refs['basicInfoForm'].updateJobData(this.job);
        this.areProgramsLoading = true;
        if (this.job) await this.fetchSchools(this.job.employer);
        if (this.job.schools) {
          const school = this.allSchools.find((school) => school.id === this.job.schools);
          this.allPrograms = get(school, 'partnership_programs', []);
        }
        this.areProgramsLoading = false;
      } catch (e) {
        if (this.$route.name === 'edit-job') {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
          this.$router.push({ name: 'jobs-management-list' });
        }
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    if (this.getLoggedInUser.role === USER_ROLES.EMPLOYER_ADMIN) {
      this.job.employer = this.getLoggedInUser.linked_entity.id;
      const employer = this.getLoggedInUser.linked_entity;
      this.job.address = employer.address;
      this.job.city = employer.city;
      this.job.state = employer.state;
      this.job.zipcode = employer.zipcode;
      this.job.country = employer.country;
      this.job.contact_phone = employer.phone;
      this.job.contact_email = employer.main_admin.email;
      this.job.job_type = JOB_TYPES.LOCAL;
      this.fetchCities(this.job.state);
      this.$refs['basicInfoForm'].updateJobData(this.job);
    }

    if (this.$route.params.id) {
      await this.fetchJobDetails();

      this.fetchCities(this.job.state);
    }
    if (this.job.org) {
      this.fetchOrgs();
    }
    this.fetchCareerPreferences();

    if (this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN) {
      this.fetchEmployers(this.job.org);
      this.fetchPrograms();
    } else if (this.getLoggedInUser.role === USER_ROLES.ORGANIZATION) {
      this.fetchEmployers(this.job.org);
    } else {
      this.fetchSchools();
    }
  },
};
</script>
