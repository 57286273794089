<template>
  <b-form class="page-section" @submit.prevent="onSubmit">
    <b-form-group label="Personality Type" label-for="personality_type" label-class="form-label">
      <div class="row px-3">
        <div v-for="option in personalityOptions" :key="option" class="mr-3">
          <div class="d-flex align-items-center">
            <b-form-radio v-model="simulation.personality" :value="option">
              <div class="mr-1">{{ option }}</div>
            </b-form-radio>
          </div>
        </div>
      </div>

      <b-form-invalid-feedback
        :state="!$v.simulation.personality.required && $v.simulation.personality.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Choose Voice" label-for="choose_voice" label-class="form-label">
      <div class="row px-3">
        <div v-for="option in SIMULATION_VOICES_OPTIONS" :key="option.value" class="mr-3">
          <div class="d-flex align-items-center">
            <b-form-radio v-model="simulation.voice" :value="option.value">
              <div class="d-flex align-items-center">
                <img class="mr-1" style="width: 25px; height: 25px" :src="getImage(option.value)" alt="" />
                <div class="mr-1">{{ option.name }}</div>
              </div>
            </b-form-radio>
            <md-icon class="clickable-item" @click.native="playSound(option.voice)">volume_up</md-icon>
          </div>
        </div>
      </div>

      <b-form-invalid-feedback :state="!$v.simulation.voice.required && $v.simulation.voice.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Greetings" label-for="greetings" label-class="form-label">
      <b-form-input
        id="greetings"
        placeholder="Enter greetings."
        v-model="simulation.greetings"
        required
        :state="!$v.simulation.greetings.required && $v.simulation.greetings.$dirty ? false : null"
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Closing" label-for="closing" label-class="form-label">
      <b-form-input
        id="closing"
        placeholder="Enter closing."
        v-model="simulation.closing"
        required
        :state="!$v.simulation.closing.required && $v.simulation.closing.$dirty ? false : null"
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { pick } from 'lodash';

import { SIMULATION_VOICES_OPTIONS } from '../../../common/constants';

export default {
  name: 'JobQualificationsForm',
  components: {},

  props: {
    simulationData: { type: Object, required: true }
  },

  data() {
    return {
      simulation: {
        voice: '',
        closing: '',
        greetings: '',
        personality: ''
      },
      SIMULATION_VOICES_OPTIONS,
      personalityOptions: ['Standard', 'Casual/Conversational', 'Challenging'],
      audioPlaying: false,
      audioFile: new Audio()
    };
  },

  validations() {
    return {
      simulation: {
        voice: { required },
        closing: { required },
        greetings: { required },
        personality: { required }
      }
    };
  },

  computed: {},

  watch: {
    simulationData: {
      handler() {
        this.simulation = pick(this.simulationData, ['voice', 'closing', 'greetings', 'personality']);
      },
      deep: true
    }
  },

  methods: {
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.simulation }
      };
    },
    playSound(sound) {
      if (this.audioPlaying) {
        this.audioFile.pause();
        this.audioPlaying = false;
      }

      this.audioFile = new Audio(sound);
      this.audioFile.play();
      this.audioPlaying = true;
    },
    getImage(voice) {
      if (voice) {
        return require(`@/assets/images/avatars/${
          this.SIMULATION_VOICES_OPTIONS.find(item => item.value === voice).image
        }`);
      }
    }
  },

  async mounted() {}
};
</script>
