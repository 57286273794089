var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Benefits","label-for":"benefits","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.job.benefits.required && _vm.$v.job.benefits.$dirty },attrs:{"id":"benefits","label":"text","reduce":function (benefit) { return benefit.value; },"placeholder":"Select job benefits","options":_vm.benefitOptions,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}}]),model:{value:(_vm.job.benefits),callback:function ($$v) {_vm.$set(_vm.job, "benefits", $$v)},expression:"job.benefits"}}),((_vm.job.benefits || []).includes('other'))?_c('b-form-input',{staticClass:"mt-1",attrs:{"id":"otherBenefits","placeholder":"Please specify","required":"","state":!_vm.$v.job.other_benefits.required && _vm.$v.job.other_benefits.$dirty ? false : null},model:{value:(_vm.job.other_benefits),callback:function ($$v) {_vm.$set(_vm.job, "other_benefits", $$v)},expression:"job.other_benefits"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":(!_vm.$v.job.benefits.required && _vm.$v.job.benefits.$dirty) ||
        (!_vm.$v.job.other_benefits.required && _vm.$v.job.other_benefits.$dirty)
          ? false
          : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Offers Career Advancement","label-for":"offers_career_advancement","label-class":"form-label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"offers_career_advancement","options":_vm.yesNoOptions,"aria-describedby":ariaDescribedby,"required":"","state":!_vm.$v.job.offers_career_advancement.required && _vm.$v.job.offers_career_advancement.$dirty ? false : null},model:{value:(_vm.job.offers_career_advancement),callback:function ($$v) {_vm.$set(_vm.job, "offers_career_advancement", $$v)},expression:"job.offers_career_advancement"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.job.offers_career_advancement.required && _vm.$v.job.offers_career_advancement.$dirty ? false : null}},[_vm._v("This field is required.")])]}}])}),(_vm.job.offers_career_advancement === false)?_c('b-form-group',{attrs:{"label":"Career Advancement Interest","label-for":"career_advancement_interest","label-class":"form-label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"career_advancement_interest","options":_vm.yesNoOptions,"aria-describedby":ariaDescribedby,"required":!_vm.job.offers_career_advancement,"state":!_vm.$v.job.career_advancement_interest.required && _vm.$v.job.career_advancement_interest.$dirty ? false : null},model:{value:(_vm.job.career_advancement_interest),callback:function ($$v) {_vm.$set(_vm.job, "career_advancement_interest", $$v)},expression:"job.career_advancement_interest"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.job.career_advancement_interest.required && _vm.$v.job.career_advancement_interest.$dirty ? false : null}},[_vm._v("This field is required.")])]}}],null,false,361432547)}):(_vm.job.offers_career_advancement === true)?_c('div',[_c('b-form-group',{attrs:{"label":"Career Offerings","label-for":"career_offerings","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.job.career_offerings.required && _vm.$v.job.career_offerings.$dirty },attrs:{"id":"career_offerings","label":"name","reduce":function (career) { return career.id; },"placeholder":"Select offered careers for career advancement","options":_vm.careerOptions,"loading":_vm.areCareersLoading,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
          var attributes = ref.attributes;
          var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.job.career_offerings.length}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.name))]),(option.id !== 'other')?_c('i',{staticClass:"ml-1"},[_vm._v("("+_vm._s(_vm.$t(("careerPrefCategories." + (option.category))))+")")]):_vm._e()]}}]),model:{value:(_vm.job.career_offerings),callback:function ($$v) {_vm.$set(_vm.job, "career_offerings", $$v)},expression:"job.career_offerings"}}),((_vm.job.career_offerings || []).includes('other'))?_c('b-form-input',{staticClass:"mt-1",attrs:{"id":"other_career_offering","placeholder":"Please specify","required":"","state":!_vm.$v.job.other_career_offering.required && _vm.$v.job.other_career_offering.$dirty ? false : null},model:{value:(_vm.job.other_career_offering),callback:function ($$v) {_vm.$set(_vm.job, "other_career_offering", $$v)},expression:"job.other_career_offering"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":(!_vm.$v.job.career_offerings.required && _vm.$v.job.career_offerings.$dirty) ||
          (!_vm.$v.job.other_career_offering.required && _vm.$v.job.other_career_offering.$dirty)
            ? false
            : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Career Offering Details","label-for":"career_offerings_details","label-class":"form-label"}},[_c('b-form-textarea',{attrs:{"id":"career_offerings_details","placeholder":"Enter career offering details.","rows":5,"required":"","state":!_vm.$v.job.career_offerings_details.required && _vm.$v.job.career_offerings_details.$dirty ? false : null},model:{value:(_vm.job.career_offerings_details),callback:function ($$v) {_vm.$set(_vm.job, "career_offerings_details", $$v)},expression:"job.career_offerings_details"}}),_c('b-form-invalid-feedback',[_vm._v("This field is required.")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }