<template>
  <div id="main">
    <page-header
      :title="title"
      :container-class="containerClass"
      :info-text="
        getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
          ? `List of all the sponsorships posted by you/your associated employers.`
          : getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
          ? 'Manage sponsorships posted by you/your organization'
          : ''
      "
    />

    <div class="page-section">
      <div :class="containerClass">
        <!-- <page-separator title="" /> -->

        <div class="d-flex justify-content-center" v-if="!totalScholarships && !isLoading && !searchTerm">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Sponsorships Posted</h4>

              <p class="text-muted">
                You haven't posted any sponsorship yet. Please click below to post a sponsorship.
              </p>

              <router-link :to="{ name: 'post-scholarship' }" class="btn btn-primary">Post a Sponsorship</router-link>
            </div>
          </div>
        </div>
        <div class="card mb-0" v-else>
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Sponsorship"
                    v-model="searchTerm"
                    @input="onSearch"
                    :disabled="isLoading"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <span class="clickable-item" v-b-popover.hover.top v-if="scholarships.length">
                  <b-btn :to="{ name: 'post-scholarship' }" exact variant="primary">Post a Sponsorship</b-btn>
                </span>
              </div>
            </div>
          </div>

          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template>

            <!-- Jobs Table -->
            <b-table
              :fields="tableColumns"
              :items="scholarships"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
            >
              <template #cell(menu)="data">
                <div class="text-center">
                  <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                    <template #button-content>
                      <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                      <span class="sr-only">Menu</span>
                    </template>

                    <b-dropdown-item :to="`/sponsorships/${data.item.id}`" target="_blank">
                      <md-icon class="text-info">remove_red_eye</md-icon> Open Public View
                    </b-dropdown-item>

                    <div
                      v-if="
                        get(getLoggedInUser, 'role_type') !== USER_ROLE_TYPES.EMPLOYER ||
                          data.item.posted_by_org === null
                      "
                    >
                      <b-dropdown-item
                        :to="{
                          name: 'edit-scholarship',
                          params: { id: data.item.id }
                        }"
                      >
                        <md-icon class="text-info">edit</md-icon> Edit Details
                      </b-dropdown-item>

                      <b-dropdown-item @click.prevent="toggleScholarshipsStatus(data.index)">
                        <md-icon :class="data.item.is_active ? 'text-danger' : 'text-success'">{{
                          data.item.is_active ? 'block' : 'check_circle_outline'
                        }}</md-icon>
                        {{ data.item.is_active ? 'Mark Sponsorship Inactive' : 'Mark Sponsorship Active' }}
                      </b-dropdown-item>

                      <b-dropdown-item
                        v-if="get(getLoggedInUser, 'role_type') !== USER_ROLE_TYPES.SUPER_ADMIN"
                        :to="{
                          name: 'manage-application-form',
                          params: { id: data.item.id, name: data.item.name }
                        }"
                      >
                        <md-icon class="text-info">class</md-icon> Manage Application Form
                      </b-dropdown-item>
                    </div>
                    <div>
                      <b-dropdown-item
                        v-if="get(getLoggedInUser, 'role_type') !== USER_ROLE_TYPES.SUPER_ADMIN"
                        :to="{
                          name: 'std-apps-list',
                          params: { id: data.item.id },
                          query: { sponsorship: data.item.title }
                        }"
                      >
                        <md-icon class="text-info">help</md-icon> Manage Applications
                      </b-dropdown-item>
                    </div>
                    <div
                      v-if="
                        get(getLoggedInUser, 'role_type') !== USER_ROLE_TYPES.EMPLOYER ||
                          data.item.posted_by_org === null
                      "
                    >
                      <b-dropdown-item
                        v-if="
                          data.item.scholarship_type === SPONSORSHIP_SUBMISSION_TYPES.IN_APP_SUBMISSION &&
                            get(getLoggedInUser, 'role_type') !== USER_ROLE_TYPES.SUPER_ADMIN
                        "
                        :to="{
                          name: 'manage-in-app-form',
                          params: { id: data.item.id }
                        }"
                      >
                        <md-icon class="text-info">file_copy</md-icon> Manually Add Application
                      </b-dropdown-item>

                      <b-dropdown-item @click.prevent="confirmDeleteJob(data.item.id)">
                        <md-icon class="text-danger">delete</md-icon> Delete Sponsorship
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                </div>
              </template>
              <template #cell(title)="data">
                <strong> {{ data.item.title }} </strong>
              </template>

              <template #cell(logo)="data">
                <fmv-avatar
                  :title="true"
                  :title-class="data.item.image_url ? 'bg-transparent' : 'bg-dark'"
                  rounded
                  no-link
                  slot="aside"
                >
                  <b-img
                    :src="data.item.image_url"
                    class="img-fluid"
                    width="40"
                    alt="Logo"
                    v-if="data.item.image_url"
                  />
                  <md-icon v-else>stars</md-icon>
                </fmv-avatar>
              </template>

              <template #cell(status)="data">
                <b-badge
                  :class="
                    'border border-' +
                      (data.item.is_expired
                        ? `secondary text-secondary`
                        : data.item.is_active
                        ? `success text-success`
                        : `danger text-danger`)
                  "
                  variant="none"
                  >{{ data.item.is_expired ? 'Expired' : data.item.is_active ? 'Active' : 'Inactive' }}</b-badge
                >
              </template>

              <template #cell(type)="data">
                <span v-for="(type, index) in data.item.student_type" :key="type">
                  {{ $t(`StudentType.${type}`) }}<span v-if="index < data.item.student_type.length - 1">, </span>
                </span>
              </template>

              <template #cell(scholarship_type)="data">
                <div class="text-center">
                  {{ $t(`sponsorshipTypes.${data.item.scholarship_type}`) }}
                </div>
              </template>

              <template #cell(posted)="data">
                {{ data.item.posted_by_org ? data.item.posted_by_org.name : '-' }}
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalScholarships"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="scholarships-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FmvAvatar } from 'fmv-avatar';
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

import Page from '@/components/Page.vue';
import Pagination from '../../components/Ui/Pagination.vue';
import MdIcon from '../../components/Ui/MdIcon.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';
import { USER_ROLES } from '../../common/constants';
import { DEFAULT_PAGE_SIZE, USER_ROLE_TYPES, SPONSORSHIP_SUBMISSION_TYPES } from '../../common/constants';

export default {
  components: {
    MdIcon,
    PageHeader,
    // PageSeparator
    Pagination,
    FmvAvatar
  },
  extends: Page,

  name: 'ScholarshipList',

  data() {
    return {
      title: 'Sponsorships',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalScholarships: 0,
      scholarships: [],
      ordering: '-is_active,-id',
      searchTerm: '',
      USER_ROLES,
      USER_ROLE_TYPES,
      SPONSORSHIP_SUBMISSION_TYPES
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    tableColumns() {
      let columns = [];
      return columns.concat([
        // { key: 'id', label: 'ID', tdClass: 'clickable-item' },
        {
          key: 'menu',
          tdClass: 'text-left'
        },
        { key: 'logo', label: '' },
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },
        { key: 'status', label: this.$t('generalMsgs.status'), tdClass: 'clickable-item' },
        { key: 'type', label: 'Student Type', tdClass: 'clickable-item' },
        ...[
          this.getLoggedInUser.role_type === USER_ROLES.SUPER_ADMIN
            ? {
                key: 'posted',
                label: 'Organization',
                tdClass: 'clickable-item'
              }
            : []
        ],
        ...[
          this.getLoggedInUser.role_type !== USER_ROLES.SUPER_ADMIN
            ? {
                key: 'scholarship_type',
                label: 'Application Type',
                tdClass: 'clickable-item'
              }
            : []
        ],
        { key: 'clicks', label: 'Clicks', tdClass: 'text-center' },
        { key: 'impressions', label: 'Impressions', tdClass: 'text-center' },

        {
          key: 'deadline',
          label: 'Expires On',
          formatter: value => {
            return moment(value).format('MM/DD/YYYY');
          },
          tdClass: 'clickable-item'
        }
        // {
        //   key: 'actions',
        //   tdClass: 'text-right',
        //   thStyle: { minWidth: '100px' },
        // },
      ]);
    }
  },

  methods: {
    ...mapActions('scholarships', ['getAllScholarship', 'deleteScholarship', 'updateScholarship']),
    get,
    async fetchScholarships(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllScholarship({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        exclude_external: 1,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params
      });
      this.scholarships = response.data.results;
      this.currentPage = pageNum;
      this.totalScholarships = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchScholarships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchScholarships();
    },

    onSearch() {
      this.debouncedSearchScholarships(this);
    },

    debouncedSearchScholarships: debounce(vm => {
      vm.fetchScholarships();
    }, 500),

    async toggleScholarshipsStatus(index) {
      const currStatus = this.scholarships[index].is_active;
      this.scholarships[index].is_active = !currStatus;

      await this.updateScholarship({ id: this.scholarships[index].id, data: { is_active: !currStatus } });
      this.makeToast({ variant: 'success', msg: `Sponsorship marked as ${currStatus ? 'Inactive' : 'Active'}` });
    },

    async confirmDeleteJob(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected sponsorship. You will not be able to access any related data after removal. If the sponsorship is no longer active, you can simply change the status to 'Inactive'.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          await this.deleteScholarship(id);
          const refreshPage = this.scholarships.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchScholarships(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Sponsorship Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    }
  },

  async mounted() {
    this.fetchScholarships();
  }
};
</script>
