<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <b-form-group label="Caregiving Experience" label-for="caregiving_experience" label-class="form-label">
      <v-select
        id="caregiving_experience"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.caregiving_experience.required && $v.job.caregiving_experience.$dirty }"
        label="text"
        v-model="job.caregiving_experience"
        :reduce="(exp) => exp.value"
        placeholder="Select required experience"
        :options="workExpOptions"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!job.caregiving_experience" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.job.caregiving_experience.required && $v.job.caregiving_experience.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Required Certifications" label-for="required_certs" label-class="form-label">
      <v-select
        id="required_certs"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.required_certs.required && $v.job.required_certs.$dirty }"
        label="text"
        v-model="job.required_certs"
        :reduce="(exp) => exp.value"
        placeholder="Select required certifications"
        :options="certificationOptions"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!job.required_certs.length" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-input
        id="other_required_certs"
        class="mt-1"
        placeholder="Please specify"
        v-model="job.other_required_certs"
        required
        v-if="(job.required_certs || []).includes('other')"
        :state="!$v.job.other_required_certs.required && $v.job.other_required_certs.$dirty ? false : null"
      />
      <b-form-invalid-feedback
        :state="
          (!$v.job.other_required_certs.required && $v.job.other_required_certs.$dirty) ||
          (!$v.job.required_certs.required && $v.job.required_certs.$dirty)
            ? false
            : null
        "
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Required Records" label-for="required_records" label-class="form-label">
      <v-select
        id="required_records"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.required_records.required && $v.job.required_records.$dirty }"
        label="text"
        v-model="job.required_records"
        :reduce="(exp) => exp.value"
        placeholder="Select required records"
        :options="recordOptions"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!job.required_records.length" v-bind="attributes" v-on="events" />
        </template>
      </v-select>
      <b-form-input
        id="other_required_records"
        class="mt-1"
        placeholder="Please specify"
        v-model="job.other_required_records"
        required
        v-if="(job.required_records || []).includes('other')"
        :state="!$v.job.other_required_records.required && $v.job.other_required_records.$dirty ? false : null"
      />
      <b-form-invalid-feedback
        :state="
          (!$v.job.other_required_records.required && $v.job.other_required_records.$dirty) ||
          (!$v.job.required_records.required && $v.job.required_records.$dirty)
            ? false
            : null
        "
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
  </b-form>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { pick } from 'lodash';

import { JOB_EXP_OPTIONS, JOB_CERT_OPTIONS, JOB_RECORD_OPTIONS } from '../../../common/constants';

export default {
  name: 'JobQualificationsForm',
  components: {},

  props: {
    jobData: { type: Object, required: true },
  },

  data() {
    return {
      job: {
        caregiving_experience: '',
        required_certs: [],
        other_required_certs: null,
        required_records: [],
        other_required_records: null,
      },

      workExpOptions: JOB_EXP_OPTIONS,
      certificationOptions: JOB_CERT_OPTIONS,
      recordOptions: JOB_RECORD_OPTIONS,
    };
  },

  validations() {
    return {
      job: {
        caregiving_experience: { required },
        required_certs: { required },
        other_required_certs: { required: requiredIf(() => this.job.required_certs.includes('other')) },
        required_records: { required },
        other_required_records: { required: requiredIf(() => this.job.required_records.includes('other')) },
      },
    };
  },

  computed: {},

  watch: {
    jobData: {
      handler() {
        this.job = pick(this.jobData, [
          'caregiving_experience',
          'required_certs',
          'other_required_certs',
          'required_records',
          'other_required_records',
        ]);
      },
      deep: true,
    },
  },

  methods: {
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.job },
      };
    },
  },

  async mounted() {},
};
</script>
