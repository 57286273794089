<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <b-form-group label="Benefits" label-for="benefits" label-class="form-label">
      <v-select
        id="benefits"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.benefits.required && $v.job.benefits.$dirty }"
        label="text"
        v-model="job.benefits"
        :reduce="(benefit) => benefit.value"
        placeholder="Select job benefits"
        :options="benefitOptions"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />
        </template>
      </v-select>

      <b-form-input
        id="otherBenefits"
        class="mt-1"
        placeholder="Please specify"
        v-model="job.other_benefits"
        required
        v-if="(job.benefits || []).includes('other')"
        :state="!$v.job.other_benefits.required && $v.job.other_benefits.$dirty ? false : null"
      />

      <b-form-invalid-feedback
        :state="
          (!$v.job.benefits.required && $v.job.benefits.$dirty) ||
          (!$v.job.other_benefits.required && $v.job.other_benefits.$dirty)
            ? false
            : null
        "
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      label="Offers Career Advancement"
      label-for="offers_career_advancement"
      label-class="form-label"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-radio-group
        id="offers_career_advancement"
        v-model="job.offers_career_advancement"
        :options="yesNoOptions"
        :aria-describedby="ariaDescribedby"
        required
        :state="!$v.job.offers_career_advancement.required && $v.job.offers_career_advancement.$dirty ? false : null"
      ></b-form-radio-group>
      <b-form-invalid-feedback
        :state="!$v.job.offers_career_advancement.required && $v.job.offers_career_advancement.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      label="Career Advancement Interest"
      label-for="career_advancement_interest"
      label-class="form-label"
      v-slot="{ ariaDescribedby }"
      v-if="job.offers_career_advancement === false"
    >
      <b-form-radio-group
        id="career_advancement_interest"
        v-model="job.career_advancement_interest"
        :options="yesNoOptions"
        :aria-describedby="ariaDescribedby"
        :required="!job.offers_career_advancement"
        :state="
          !$v.job.career_advancement_interest.required && $v.job.career_advancement_interest.$dirty ? false : null
        "
      ></b-form-radio-group>
      <b-form-invalid-feedback
        :state="
          !$v.job.career_advancement_interest.required && $v.job.career_advancement_interest.$dirty ? false : null
        "
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <div v-else-if="job.offers_career_advancement === true">
      <b-form-group label="Career Offerings" label-for="career_offerings" label-class="form-label">
        <v-select
          id="career_offerings"
          class="form-control v-select-custom"
          :class="{ 'is-invalid': !$v.job.career_offerings.required && $v.job.career_offerings.$dirty }"
          label="name"
          v-model="job.career_offerings"
          :reduce="(career) => career.id"
          placeholder="Select offered careers for career advancement"
          :options="careerOptions"
          :loading="areCareersLoading"
          multiple
        >
          <template #search="{ attributes, events }">
            <input class="vs__search" :required="!job.career_offerings.length" v-bind="attributes" v-on="events" />
          </template>

          <template slot="option" slot-scope="option">
            <span>{{ option.name }}</span>
            <i v-if="option.id !== 'other'" class="ml-1">({{ $t(`careerPrefCategories.${option.category}`) }})</i>
          </template>
        </v-select>

        <b-form-input
          id="other_career_offering"
          class="mt-1"
          placeholder="Please specify"
          v-model="job.other_career_offering"
          required
          v-if="(job.career_offerings || []).includes('other')"
          :state="!$v.job.other_career_offering.required && $v.job.other_career_offering.$dirty ? false : null"
        />
        <b-form-invalid-feedback
          :state="
            (!$v.job.career_offerings.required && $v.job.career_offerings.$dirty) ||
            (!$v.job.other_career_offering.required && $v.job.other_career_offering.$dirty)
              ? false
              : null
          "
          >This field is required.</b-form-invalid-feedback
        >
      </b-form-group>

      <b-form-group label="Career Offering Details" label-for="career_offerings_details" label-class="form-label">
        <b-form-textarea
          id="career_offerings_details"
          placeholder="Enter career offering details."
          :rows="5"
          v-model="job.career_offerings_details"
          required
          :state="!$v.job.career_offerings_details.required && $v.job.career_offerings_details.$dirty ? false : null"
        />
        <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { pick } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'JobBenefitsForm',
  components: {},

  props: {
    jobData: { type: Object, required: true },
    careerOptions: { type: Array, required: true },
    areCareersLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      job: {
        benefits: [],
        other_benefits: null,
        offers_career_advancement: null,
        career_advancement_interest: null,
        career_offerings: [],
        other_career_offering: null,
        career_offerings_details: '',
      },

      benefitOptions: [
        { value: 'healthcare_insurance', text: 'Healthcare Insurance' },
        { value: 'tuition_reimbursement', text: 'Tuition Reimbursement' },
        { value: 'childcare', text: 'Childcare' },
        { value: 'paid_mileage', text: 'Paid Mileage' },
        { value: 'other', text: 'Other' },
      ],
      yesNoOptions: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
    };
  },

  validations() {
    return {
      job: {
        benefits: { required },
        other_benefits: { required: requiredIf(() => this.job.benefits.includes('other')) },
        offers_career_advancement: { required },
        career_advancement_interest: { required: requiredIf(() => this.job.offers_career_advancement === false) },
        career_offerings: { required: requiredIf(() => !!this.job.offers_career_advancement) },
        other_career_offering: { required: requiredIf(() => this.job.career_offerings.includes('other')) },
        career_offerings_details: { required: requiredIf(() => !!this.job.offers_career_advancement) },
      },
    };
  },

  computed: {},

  watch: {
    jobData: {
      handler() {
        this.job = pick(this.jobData, [
          'benefits',
          'other_benefits',
          'offers_career_advancement',
          'career_advancement_interest',
          'career_offerings',
          'other_career_offering',
          'career_offerings_details',
        ]);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('careerPreference', ['getAllCareerPreferences']),

    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.job },
      };
    },
  },

  async mounted() {},
};
</script>
