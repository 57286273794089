<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <b-form-group
      label="Post Simulation for"
      label-for="isOrg"
      label-class="form-label"
      v-slot="{ ariaDescribedby }"
      v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN"
    >
      <b-form-radio-group
        id="isOrg"
        v-model="isOrg"
        :options="isOrgOptions"
        :aria-describedby="ariaDescribedby"
        @change="fetchOrgsForSelection"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      label="Organization"
      label-for="organization"
      label-class="form-label"
      v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && isOrg"
    >
      <v-select
        id="organization"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.simulation.org.required && $v.simulation.org.$dirty }"
        label="name"
        v-model="simulation.org"
        :reduce="opt => opt.id"
        placeholder="Select Organization"
        :options="allOrgs"
        :loading="areOrgsLoading"
        @input="changeSelectedOrg"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.simulation.org.required && $v.simulation.org.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="`Employer${isOrg ? '(s)' : ''}`"
      label-for="employer"
      label-class="form-label"
      v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN || getLoggedInUser.role === USER_ROLES.ORGANIZATION"
    >
      <v-select
        id="employer"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.simulation.employer.required && $v.simulation.employer.$dirty }"
        label="name"
        v-model="simulation.employer"
        :reduce="opt => opt.id"
        :placeholder="getLoggedInUser.role === USER_ROLES.ORGANIZATION ? 'All Associated Employers' : 'Select Employer'"
        :options="allEmployers"
        :loading="areEmployersLoading"
        @input="changeSelectedEmployer"
        :multiple="isOrg"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!simulation.employer" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.simulation.employer.required && $v.simulation.employer.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group
      :label="`School${isOrg ? '(s)' : ''}`"
      label-for="school"
      label-class="form-label"
      v-if="this.getLoggedInUser.role !== USER_ROLES.ORGANIZATION"
    >
      <!-- <label class="form-label">
        School
        <i
          class="material-icons icon-16pt clickable-item text-muted"
          v-b-popover.hover.top="`This simulation will be displayed to your partnered school on tranistion portal.`"
          >info</i
        >
      </label> -->
      <v-select
        id="school"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.simulation.schools.required && $v.simulation.schools.$dirty }"
        label="name"
        v-model="simulation.schools"
        :reduce="opt => opt.id"
        :placeholder="`Select School${isOrg ? '(s)' : ''}`"
        :options="allSchools"
        :loading="areSchoolsLoading"
        @input="schoolChanged"
        :multiple="isOrg"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!simulation.schools" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.simulation.schools.required && $v.simulation.schools.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Associated Programs" label-for="associated_programs" label-class="form-label">
      <v-select
        id="associated_programs"
        class="form-control v-select-custom"
        label="title"
        v-model="simulation.associated_programs"
        :reduce="associated_programs => associated_programs.id"
        placeholder="Select Enrolled Program"
        :options="allPrograms"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!simulation.associated_programs" v-bind="attributes" v-on="events" />
        </template>
        <template slot="option" slot-scope="option">
          <span>{{ option.title }}</span>
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.simulation.associated_programs.required && $v.simulation.associated_programs.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <page-separator title="Feature this simulation for" />
    <b-form-group class="row-align-items-center">
      <label class="form-label">
        Universities
        <i
          class="material-icons icon-16pt clickable-item text-muted"
          v-b-popover.hover.top="
            `Select universities for which you want to sponsor this simulation for, on CareCampus.`
          "
          >info</i
        >
      </label>
      <v-select
        id="universities"
        class="form-control v-select-custom"
        label="name"
        v-model="simulation.universities"
        :reduce="opt => opt.id"
        placeholder="Select Universities"
        :options="allUniversities"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img
                :src="option.small_logo_url"
                class="img-fluid"
                width="20"
                alt="Logo"
                v-if="option.small_logo_url"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
    </b-form-group>
  </b-form>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { pick } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLES, USER_ROLE_TYPES } from '../../../common/constants';
import { FmvAvatar } from 'fmv-avatar';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'JobAudienceForm',
  components: { FmvAvatar, PageSeparator },

  props: {
    simulationData: { type: Object, required: true },
    allEmployers: { type: Array, default: () => [] },
    areEmployersLoading: { type: Boolean, default: false },
    areSchoolsLoading: { type: Boolean, default: false },
    allSchools: { type: Array, default: () => [] },
    allPrograms: { type: Array, default: () => [] },
    allOrgs: { type: Array, default: () => [] },
    areOrgsLoading: { type: Boolean, default: false },
    // orgData: { type: Object, default: null },
    isOrgData: { type: Boolean, default: false }
  },

  data() {
    return {
      isOrg: false,
      simulation: {
        schools: null,
        employer: null,
        org: null,
        associated_programs: null,
        universities: []
      },

      isOrgOptions: [
        { value: false, text: 'Employer' },
        { value: true, text: 'Organization' }
      ],
      allUniversities: [],

      USER_ROLE_TYPES,
      USER_ROLES
    };
  },

  validations() {
    return {
      simulation: {
        employer: {
          required: requiredIf(() => this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN && !this.isOrg)
        },
        org: {
          required: requiredIf(() => this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN && this.isOrg)
        },
        schools: {
          required: requiredIf(() => this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER || !this.isOrg)
        },
        associated_programs: { required }
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser'])
  },

  watch: {
    simulationData: {
      handler() {
        this.simulation = pick(this.simulationData, [
          'employer',
          'schools',
          'associated_programs',
          'org',
          'universities'
        ]);
        this.isOrg = this.isOrgData;
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('uni', ['getAllUnis']),

    async fetchAllUniversities() {
      // this.areUnisLoading = true;
      try {
        const res = await this.getAllUnis({});
        this.allUniversities = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      // this.areUnisLoading = false;
    },

    changeSelectedOrg(value) {
      if (value) {
        this.simulation.org = value;
      } else {
        this.simulation.org = null;
        this.simulation.employer = null;
        this.simulation.schools = null;
        this.simulation.associated_programs = [];
      }
      this.$emit('orgChanged', value);
    },
    changeSelectedEmployer(value) {
      if (value) {
        this.simulation.employer = value;
      } else {
        this.simulation.employer = null;
      }
      this.simulation.schools = null;

      this.$emit('employerChanged', value);
    },
    schoolChanged(value) {
      if (value) {
        this.simulation.schools = value;
      } else {
        this.simulation.schools = null;
      }

      // this.simulation.associated_programs = [];
      this.$emit('schoolChanged', { value: value, employer: this.simulation.employer });
    },
    fetchOrgsForSelection(value) {
      this.simulation.org = null;
      this.simulation.employer = null;
      this.simulation.schools = null;
      this.simulation.associated_programs = [];

      this.$emit('orgsFetched', value);
    },
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.simulation }
      };
    }
  },

  async mounted() {
    this.fetchAllUniversities();
  }
};
</script>
