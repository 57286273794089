<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <b-form-group
      label="Post Job for"
      label-for="isOrg"
      label-class="form-label"
      v-slot="{ ariaDescribedby }"
      v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN"
    >
      <b-form-radio-group
        id="isOrg"
        v-model="isOrg"
        :options="isOrgOptions"
        :aria-describedby="ariaDescribedby"
        @change="fetchOrgsForSelection"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group
      label="Organization"
      label-for="organization"
      label-class="form-label"
      v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && isOrg"
    >
      <v-select
        id="organization"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.org.required && $v.job.org.$dirty }"
        label="name"
        v-model="job.org"
        :reduce="(opt) => opt.id"
        placeholder="Select Organization"
        :options="allOrgs"
        :loading="areOrgsLoading"
        @input="changeSelectedOrg"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.job.org.required && $v.job.org.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      :label="$t('employerMsgs.employer')"
      label-for="employer"
      label-class="form-label"
      v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN || getLoggedInUser.role === USER_ROLES.ORGANIZATION"
    >
      <v-select
        id="employer"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.employer.required && $v.job.employer.$dirty }"
        label="name"
        v-model="job.employer"
        :reduce="(opt) => opt.id"
        placeholder="Select Employer"
        :options="allEmployers"
        :loading="areEmployersLoading"
        @input="changeSelectedEmployer"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!job.employer" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.job.employer.required && $v.job.employer.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <!-- <b-form-group
      label="Job Type"
      label-for="job_type"
      label-class="form-label"
      v-slot="{ ariaDescribedby }"
      v-if="getLoggedInUser.role_type === USER_ROLES.SUPER_ADMIN"
    >
      <b-form-radio-group
        id="job_type"
        v-model="job.job_type"
        :options="jobTypeOptions"
        :aria-describedby="ariaDescribedby"
        required
        :state="!$v.job.job_type.required && $v.job.job_type.$dirty ? false : null"
        :disabled="$route.params.id && getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER"
        @input="jobTypeChanged"
      ></b-form-radio-group>
      <b-form-invalid-feedback :state="!$v.job.job_type.required && $v.job.job_type.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group> -->

    <b-form-group class="row-align-items-center" v-if="job.job_type === JOB_TYPES.LOCAL">
      <label class="form-label">
        School
        <i
          class="material-icons icon-16pt clickable-item text-muted"
          v-b-popover.hover.top="`This job will be displayed to your partnered school on tranistion portal.`"
          >info</i
        >
      </label>
      <v-select
        id="school"
        class="form-control v-select-custom"
        :class="{ 'is-invalid': !$v.job.schools.required && $v.job.schools.$dirty }"
        label="name"
        v-model="job.schools"
        :reduce="(opt) => opt.id"
        placeholder="Select School"
        :options="allSchools"
        :loading="areSchoolsLoading"
        @input="schoolChanged"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!job.schools" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
      <b-form-invalid-feedback :state="!$v.job.schools.required && $v.job.schools.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Associated Programs" label-for="associated_programs" label-class="form-label">
      <v-select
        id="associated_programs"
        class="form-control v-select-custom"
        label="title"
        v-model="job.associated_programs"
        :reduce="(associated_programs) => associated_programs.id"
        placeholder="Select Enrolled Program"
        :options="allPrograms"
        :multiple="job.job_type === JOB_TYPES.LOCAL ? false : true"
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!job.associated_programs" v-bind="attributes" v-on="events" />
        </template>
        <template slot="option" slot-scope="option">
          <span>{{ option.title }}</span>
        </template>
      </v-select>
      <b-form-invalid-feedback
        :state="!$v.job.associated_programs.required && $v.job.associated_programs.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
    <page-separator title="Feature this job for" />
    <b-form-group class="row-align-items-center">
      <label class="form-label">
        Universities
        <i
          class="material-icons icon-16pt clickable-item text-muted"
          v-b-popover.hover.top="`Select universities for which you want to sponsor this job for, on CareCampus.`"
          >info</i
        >
      </label>
      <v-select
        id="universities"
        class="form-control v-select-custom"
        label="name"
        v-model="job.universities"
        :reduce="(opt) => opt.id"
        placeholder="Select Universities"
        :options="allUniversities"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" v-bind="attributes" v-on="events" />
        </template>

        <template slot="option" slot-scope="option">
          <div class="d-flex align-items-center">
            <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
              <b-img
                :src="option.small_logo_url"
                class="img-fluid"
                width="20"
                alt="Logo"
                v-if="option.small_logo_url"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>
            <span>{{ option.name }}</span>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          {{ option.name }}
        </template>
      </v-select>
    </b-form-group>
    <b-form-group class="row-align-items-center">
      <label class="form-label">
        Workforce Programs
        <i
          class="material-icons icon-16pt clickable-item text-muted"
          v-b-popover.hover.top="`Select workforce programs for which you want to sponsor this job for, on CareClimb.`"
          >info</i
        >
      </label>
      <v-select
        id="best_school_cities"
        class="form-control v-select-custom"
        label="name"
        v-model="job.best_school_cities"
        :reduce="(best_cities) => best_cities.id"
        placeholder="Select Workforce Programs"
        :options="allCities"
        :loading="areCitiesLoading"
        multiple
      >
        <template #search="{ attributes, events }">
          <input class="vs__search" :required="!job.best_school_cities" v-bind="attributes" v-on="events" />
        </template>
        <template slot="option" slot-scope="option">
          <span>{{ option.name }}</span>
        </template>
      </v-select>
      <!-- <b-form-invalid-feedback
        :state="!$v.job.associated_programs.required && $v.job.associated_programs.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      > -->
    </b-form-group>
  </b-form>
</template>

<script>
import { requiredIf, required } from 'vuelidate/lib/validators';
import { pick } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLES, JOB_TYPES, USER_ROLE_TYPES } from '../../../common/constants';
import { FmvAvatar } from 'fmv-avatar';
import PageSeparator from '@/components/Ui/PageSeparator.vue';

export default {
  name: 'JobAudienceForm',
  components: { FmvAvatar, PageSeparator },

  props: {
    jobData: { type: Object, required: true },
    allEmployers: { type: Array, default: () => [] },
    areEmployersLoading: { type: Boolean, default: false },
    areSchoolsLoading: { type: Boolean, default: false },
    allSchools: { type: Array, default: () => [] },
    allPrograms: { type: Array, default: () => [] },
    allOrgs: { type: Array, default: () => [] },
    allCities: { type: Array, default: () => [] },
    areCitiesLoading: { type: Boolean, default: false },
    areOrgsLoading: { type: Boolean, default: false },
    areProgramsLoading: { type: Boolean, default: false },
    orgData: { type: Object, default: null },
    isOrgData: { type: Boolean, default: false },
  },

  data() {
    return {
      isOrg: false,
      job: {
        schools: null,
        universities: [],
        best_school_cities: [],
        employer: null,
        org: null,
        job_type: JOB_TYPES.LOCAL,
        associated_programs: null,
      },
      allUniversities: [],
      benefitOptions: [
        { value: 'healthcare_insurance', text: 'Healthcare Insurance' },
        { value: 'tuition_reimbursement', text: 'Tuition Reimbursement' },
        { value: 'childcare', text: 'Childcare' },
        { value: 'paid_mileage', text: 'Paid Mileage' },
        { value: 'other', text: 'Other' },
      ],
      yesNoOptions: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' },
      ],
      isOrgOptions: [
        { value: false, text: 'Employer' },
        { value: true, text: 'Organization' },
      ],
      payRateOptions: [
        { value: 'hourly', text: 'Hourly' },
        { value: 'weekly', text: 'Weekly' },
        { value: 'monthly', text: 'Monthly' },
        { value: 'annually', text: 'Annually' },
      ],
      activeOptions: [
        { value: true, text: 'Active' },
        { value: false, text: 'Inactive' },
      ],
      jobTypeOptions: [
        { value: JOB_TYPES.STANDARD, text: 'Standard' },
        { value: JOB_TYPES.LOCAL, text: 'Local' },
      ],
      USER_ROLE_TYPES,
      USER_ROLES,
      JOB_TYPES,
    };
  },

  validations() {
    return {
      job: {
        schools: { required: requiredIf(() => this.job.job_type === JOB_TYPES.LOCAL) },
        org: { required: requiredIf(() => this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && this.isOrg) },
        // job_type: { required: requiredIf(() => this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN) },
        employer: {},
        associated_programs: { required },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    jobData: {
      handler() {
        this.job = pick(this.jobData, [
          'employer',
          'job_type',
          'schools',
          'universities',
          'best_school_cities',
          'associated_programs',
          'org',
        ]);
        this.$nextTick(() => {
          this.job.associated_programs = this.jobData.associated_programs;
          this.job.universities = this.jobData.universities;
        });
        this.isOrg = this.isOrgData;
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('uni', ['getAllUnis']),
    changeSelectedOrg(value) {
      if (value) {
        this.job.org = value;
      } else {
        this.job.org = null;
        this.job.employer = null;
        this.job.schools = null;
        this.job.associated_programs = [];
      }
      this.$emit('orgChanged', value);
    },
    changeSelectedEmployer(value) {
      if (value) {
        const employer = this.allEmployers.find((emp) => emp.id === value);
        this.job.employer = value;
        this.job.address = employer.address;
        this.job.city = employer.city;
        this.job.state = employer.state;
        this.job.zipcode = employer.zipcode;
        this.job.country = employer.country;
      } else {
        this.job.employer = null;
        this.job.address = this.job.city = this.job.state = this.job.zipcode = null;
        this.job.country = 'United States';
      }
      this.job.schools = null;
      if (this.job.job_type === JOB_TYPES.LOCAL) {
        this.job.associated_programs = null;
      }
      this.$emit('employerChanged', value);
    },
    // jobTypeChanged(value) {
    //   if (value === JOB_TYPES.LOCAL) {
    //     this.job.job_type = value;
    //   } else if (value === JOB_TYPES.STANDARD) {
    //     this.job.job_type = value;
    //     this.job.schools = null;
    //   }
    //   this.$emit('typeChanged', value);
    // },
    schoolChanged(value) {
      if (value) {
        this.job.schools = value;
      } else {
        this.job.schools = null;
      }

      this.job.associated_programs = this.job.job_type === JOB_TYPES.STANDARD ? [] : null;
      this.$emit('schoolChanged', { value: value, employer: this.job.employer });
    },
    async fetchAllUniversities() {
      this.areUnisLoading = true;
      try {
        const res = await this.getAllUnis({});
        this.allUniversities = res.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areUnisLoading = false;
    },
    fetchOrgsForSelection(value) {
      this.job.org = null;
      this.job.employer = null;
      this.job.schools = null;
      this.job.associated_programs = [];

      this.$emit('orgsFetched', value);
    },
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.job },
      };
    },
  },

  async mounted() {
    this.fetchAllUniversities();
  },
};
</script>
