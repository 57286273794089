<template>
  <div id="main">
    <page-header :title="title" :container-class="containerClass" :info-text="''" />

    <div class="page-section">
      <div :class="containerClass">
        <div class="d-flex justify-content-center" v-if="!totalScholarships && !isLoading && !searchTerm">
          <div class="card card-block card-stretch card-height col-md-12">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Sponsorships Posted</h4>

              <p class="text-muted">
                You haven't posted any sponsorship yet. Please click below to post a sponsorship.
              </p>

              <router-link :to="{ name: 'post-external-scholarship' }" class="btn btn-primary"
                >Post External Sponsorship</router-link
              >
            </div>
          </div>
        </div>
        <div class="card mb-0" v-else>
          <div class="card-header">
            <div class="row align-items-center" style="white-space: nowrap">
              <!-- Search -->
              <div class="col-lg-auto">
                <form class="search-form search-form--light d-lg-inline-flex mb-8pt mb-lg-0" @submit.prevent="">
                  <b-form-input
                    class="w-lg-auto"
                    placeholder="Search Sponsorship"
                    v-model="searchTerm"
                    @input="onSearch"
                    :disabled="isLoading"
                  />
                  <b-btn variant="flush" type="submit">
                    <md-icon v-text="'search'" />
                  </b-btn>
                </form>
              </div>

              <div class="col-lg d-flex flex-wrap justify-content-end">
                <span class="clickable-item" v-b-popover.hover.top v-if="scholarships.length">
                  <b-btn :to="{ name: 'post-external-scholarship' }" exact variant="primary"
                    >Post External Sponsorship</b-btn
                  >
                </span>
              </div>
            </div>
          </div>

          <b-skeleton-wrapper :loading="isLoading">
            <template #loading>
              <b-skeleton-table :rows="5" :columns="4" :table-props="{ hover: true }"></b-skeleton-table>
            </template>

            <!-- Jobs Table -->
            <b-table
              :fields="tableColumns"
              :items="scholarships"
              :busy="isLoading"
              head-variant="light"
              class="table-nowrap"
              responsive
              no-local-sorting
              @sort-changed="onSortChange"
            >
              <template #cell(menu)="data">
                <div>
                  <b-dropdown :boundary="'main'" no-flip variant="flush" toggle-class="text-muted" no-caret>
                    <template #button-content>
                      <i class="material-icons icon-24pt card-course__icon-favorite text-info">more_vert</i>
                      <span class="sr-only">Menu</span>
                    </template>

                    <b-dropdown-item
                      :to="{
                        name: 'edit-external-scholarship',
                        params: { id: data.item.id }
                      }"
                    >
                      <md-icon class="text-info">edit</md-icon> Edit Details
                    </b-dropdown-item>

                    <b-dropdown-item @click.prevent="toggleScholarshipsStatus(data.index)">
                      <md-icon :class="data.item.is_active ? 'text-danger' : 'text-success'">{{
                        data.item.is_active ? 'block' : 'check_circle_outline'
                      }}</md-icon>
                      {{ data.item.is_active ? 'Mark Sponsorship Inactive' : 'Mark Sponsorship Active' }}
                    </b-dropdown-item>

                    <b-dropdown-item @click.prevent="confirmDeleteJob(data.item.id)">
                      <md-icon class="text-danger">delete</md-icon> Delete Sponsorship
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
              <template #cell(title)="data">
                <strong> {{ data.item.title }} </strong>
              </template>

              <template #cell(status)="data">
                <b-badge
                  :class="'border border-' + (data.item.is_active ? `success text-success` : `danger text-danger`)"
                  variant="none"
                  >{{ data.item.is_active ? 'Active' : 'Inactive' }}</b-badge
                >
              </template>
            </b-table>

            <!-- Footer Pagination -->
            <div class="card-footer">
              <pagination
                v-model="currentPage"
                :total-rows="totalScholarships"
                :per-page="perPage"
                @change="onPageChange"
                aria-controls="scholarships-table"
              />
            </div>
          </b-skeleton-wrapper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce, get } from 'lodash';
import { mapActions, mapGetters } from 'vuex';

import Page from '@/components/Page.vue';
import Pagination from '@/components/Ui/Pagination.vue';

import PageHeader from '@/components/Ui/PageHeader.vue';
import { DEFAULT_PAGE_SIZE } from '@/common/constants';

export default {
  components: {
    PageHeader,

    Pagination
  },
  extends: Page,

  name: 'ScholarshipList',

  data() {
    return {
      title: 'External Sponsorships',
      isLoading: false,
      perPage: DEFAULT_PAGE_SIZE,
      currentPage: 1,
      totalScholarships: 0,
      scholarships: [],
      ordering: '-is_active,-id',
      searchTerm: ''
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),

    tableColumns() {
      let columns = [];
      return columns.concat([
        {
          key: 'menu',
          tdClass: 'text-left'
        },
        { key: 'title', label: 'Title', tdClass: 'clickable-item' },
        { key: 'status', label: this.$t('generalMsgs.status'), tdClass: 'clickable-item' },
        { key: 'external_organization', label: 'Organization Name', tdClass: 'clickable-item' }
      ]);
    }
  },

  methods: {
    ...mapActions('scholarships', ['getAllScholarship', 'deleteScholarship', 'updateScholarship']),
    get,
    async fetchScholarships(pageNum = 1, params = {}) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      const response = await this.getAllScholarship({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        scholarship_type: 'by_superadmin',
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params
      });
      this.scholarships = response.data.results;
      this.currentPage = pageNum;
      this.totalScholarships = response.data.count;
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchScholarships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchScholarships();
    },

    onSearch() {
      this.debouncedSearchScholarships(this);
    },

    debouncedSearchScholarships: debounce(vm => {
      vm.fetchScholarships();
    }, 500),

    async toggleScholarshipsStatus(index) {
      const currStatus = this.scholarships[index].is_active;
      this.scholarships[index].is_active = !currStatus;

      await this.updateScholarship({
        id: this.scholarships[index].id,
        data: { scholarship_type: 'by_superadmin', is_active: !currStatus }
      });
      this.makeToast({ variant: 'success', msg: `Sponsorship marked as ${currStatus ? 'Inactive' : 'Active'}` });
    },

    async confirmDeleteJob(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          `Please confirm that you want to remove the selected sponsorship. You will not be able to access any related data after removal. If the sponsorship is no longer active, you can simply change the status to 'Inactive'.`,
          {
            title: 'Are you sure?',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          }
        );
        if (isConfirmed) {
          await this.deleteScholarship(id);
          const refreshPage = this.scholarships.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchScholarships(refreshPage || 1);
          this.makeToast({ variant: 'success', msg: 'Sponsorship Successfully Removed' });
        }
      } catch (error) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    }
  },

  async mounted() {
    this.fetchScholarships();
  }
};
</script>
