<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <b-form-group label="Simulation Type" label-for="simulation_type" label-class="form-label">
      <div v-for="option in options" :key="option.value">
        <b-form-radio class="my-2" v-model="simulation.simulation_type" :value="option.value">
          <span>{{ option.title }}: </span>
          <span style="font-size: 14px; color: grey">{{ option.description }}</span>
        </b-form-radio>
      </div>

      <b-form-invalid-feedback
        :state="!$v.simulation.simulation_type.required && $v.simulation.simulation_type.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Image" label-for="image" label-class="form-label" class="row-align-items-center">
      <b-media class="align-items-center" vertical-align="center">
        <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
          <span v-if="isImgUploading">...</span>
          <b-img :src="simulation.image" class="img-fluid" width="40" alt="Image" v-else-if="simulation.image" />
          <i class="fas fa-shopping-bag fa-lg" v-else></i>
        </fmv-avatar>

        <image-uploader
          @image="e => setImage(e, 'image')"
          :isRequired="!simulation.image"
          :isUploading="isImgUploading"
          placeholder="Select Image"
        />
      </b-media>
      <b-form-invalid-feedback :state="!$v.simulation.image.required && $v.simulation.image.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Title" label-for="title" label-class="form-label">
      <b-form-input
        id="title"
        placeholder="Enter simulation's title."
        v-model.trim="simulation.title"
        :maxlength="250"
        required
        :state="
          ($v.simulation.title.required && $v.simulation.title.maxLength) || !$v.simulation.title.$dirty ? null : false
        "
      />
      <b-form-invalid-feedback v-if="!$v.simulation.title.required">
        This field is required.
      </b-form-invalid-feedback>
      <b-form-invalid-feedback v-if="!$v.simulation.title.maxLength">
        Title must be at most 250 characters long.
      </b-form-invalid-feedback>
    </b-form-group>

    <b-form-group v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN" label-class="form-label">
      <b-form-checkbox v-model="simulation.is_featured" name="check-button" switch>
        Is Featured?
      </b-form-checkbox>
    </b-form-group>

    <b-form-group label="Instructions" label-for="instructions" label-class="form-label">
      <b-form-textarea
        id="questions"
        placeholder="Enter simulation's instructions."
        :rows="5"
        v-model="simulation.instructions"
        required
        :state="!$v.simulation.instructions.required && $v.simulation.instructions.$dirty ? false : null"
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>

    <b-form-group label="Details" label-for="details" label-class="form-label">
      <editor
        :init="{
          height: 400,
          menubar: 'edit view insert format tools table help',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
        }"
        v-model="simulation.details"
        placeholder="Enter simulation's details."
      />
      <b-form-invalid-feedback :state="!$v.simulation.details.required && $v.simulation.details.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>
  </b-form>
</template>

<script>
import axios from 'axios';
import { pick, head } from 'lodash';
import Editor from '@tinymce/tinymce-vue';
import { required, maxLength } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import ImageUploader from '@/components/ImageUploader.vue';
import { FmvAvatar } from 'fmv-avatar';
import { USER_ROLES } from '../../../common/constants';

export default {
  name: 'JobBasicInfoForm',
  components: { Editor, ImageUploader, FmvAvatar },

  props: {
    simulationData: { type: Object, required: true },
  },

  data() {
    return {
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,
      uploadFile: { image: null },
      uploadPercent: { image: 0 },
      uploadCancelTokenSource: { image: null },

      isImgUploading: false,
      options: [
        {
          title: `Simulation Type
General Professional Competency`,
          value: 'general_professional',
          description: `A broader assessment of overall professional demeanor, work ethic, general knowledge,
and soft skills.`,
        },
        // TODO: add in backend
        // {
        //   title: `Basic Care and Procedures`,
        //   value: 'basic_care_and_procedures',
        //   description: `Focuses on skills in routine patient care and basic medical procedures.`
        // },
        {
          title: `Behavioral and Situational`,
          value: 'behavioral_and_situational',
          description: `Assesses responses to common workplace situations and behavioral patterns.`,
        },
        {
          title: `Communication Skills`,
          value: 'communication_skills',
          description: `Evaluates effective communication with patients, families, and team members.`,
        },
        {
          title: `Teamwork and Collaboration`,
          value: 'teamwork_and_collaboration',
          description: `Focuses on the ability to work as part of a healthcare team.`,
        },
      ],

      simulation: {
        simulation_type: '',
        title: '',
        image: '',
        instructions: '',
        details: '',
        is_featured: false,
      },
      USER_ROLES,
    };
  },

  validations() {
    return {
      simulation: {
        image: { required },
        title: { required, maxLength: maxLength(250) },
        instructions: { required },
        simulation_type: { required },
        details: { required },
      },
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
  },

  watch: {
    simulationData: {
      handler() {
        this.simulation = pick(this.simulationData, [
          'simulation_type',
          'title',
          'instructions',
          'image',
          'details',
          'is_featured',
        ]);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('simulations', ['getSimulationImageUploadPresignedUrl']),
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    head,
    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.simulation },
      };
    },

    setImage(file, fileType) {
      this.uploadFile[fileType] = file;
      this.uploadImage(fileType);
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isImgUploading = uploadType === 'image';

      try {
        const urlResp = await this.getSimulationImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type,
          upload_type: 'images',
        });
        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token,
          },
        });
        if (uploadType === 'image') {
          this.simulation.image = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'image') this.isImgUploading = false;
    },

    updateJobData(data) {
      this.simulation = pick(data, [
        'job_type',
        'title',
        'description',
        'is_active',
        'type',
        'schedule',
        'application_link',
        'minimum_pay',
        'maximum_pay',
        'pay_rate',
        'no_of_hires',
        'schools',
        'address',
        'city',
        'state',
        'zipcode',
        'country',
      ]);
    },
  },

  async mounted() {},
};
</script>
