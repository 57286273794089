var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.getLoggedInUser.role === _vm.USER_ROLES.SUPER_ADMIN)?_c('b-form-group',{attrs:{"label":"Post Simulation for","label-for":"isOrg","label-class":"form-label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"isOrg","options":_vm.isOrgOptions,"aria-describedby":ariaDescribedby},on:{"change":_vm.fetchOrgsForSelection},model:{value:(_vm.isOrg),callback:function ($$v) {_vm.isOrg=$$v},expression:"isOrg"}})]}}],null,false,2542522452)}):_vm._e(),(_vm.getLoggedInUser.role === _vm.USER_ROLES.SUPER_ADMIN && _vm.isOrg)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"organization","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.simulation.org.required && _vm.$v.simulation.org.$dirty },attrs:{"id":"organization","label":"name","reduce":function (opt) { return opt.id; },"placeholder":"Select Organization","options":_vm.allOrgs,"loading":_vm.areOrgsLoading},on:{"input":_vm.changeSelectedOrg},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,3108784906),model:{value:(_vm.simulation.org),callback:function ($$v) {_vm.$set(_vm.simulation, "org", $$v)},expression:"simulation.org"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.simulation.org.required && _vm.$v.simulation.org.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),(_vm.getLoggedInUser.role === _vm.USER_ROLES.SUPER_ADMIN || _vm.getLoggedInUser.role === _vm.USER_ROLES.ORGANIZATION)?_c('b-form-group',{attrs:{"label":("Employer" + (_vm.isOrg ? '(s)' : '')),"label-for":"employer","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.simulation.employer.required && _vm.$v.simulation.employer.$dirty },attrs:{"id":"employer","label":"name","reduce":function (opt) { return opt.id; },"placeholder":_vm.getLoggedInUser.role === _vm.USER_ROLES.ORGANIZATION ? 'All Associated Employers' : 'Select Employer',"options":_vm.allEmployers,"loading":_vm.areEmployersLoading,"multiple":_vm.isOrg},on:{"input":_vm.changeSelectedEmployer},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.simulation.employer}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,591479392),model:{value:(_vm.simulation.employer),callback:function ($$v) {_vm.$set(_vm.simulation, "employer", $$v)},expression:"simulation.employer"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.simulation.employer.required && _vm.$v.simulation.employer.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),(this.getLoggedInUser.role !== _vm.USER_ROLES.ORGANIZATION)?_c('b-form-group',{attrs:{"label":("School" + (_vm.isOrg ? '(s)' : '')),"label-for":"school","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.simulation.schools.required && _vm.$v.simulation.schools.$dirty },attrs:{"id":"school","label":"name","reduce":function (opt) { return opt.id; },"placeholder":("Select School" + (_vm.isOrg ? '(s)' : '')),"options":_vm.allSchools,"loading":_vm.areSchoolsLoading,"multiple":_vm.isOrg},on:{"input":_vm.schoolChanged},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.simulation.schools}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,1187147442),model:{value:(_vm.simulation.schools),callback:function ($$v) {_vm.$set(_vm.simulation, "schools", $$v)},expression:"simulation.schools"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.simulation.schools.required && _vm.$v.simulation.schools.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Associated Programs","label-for":"associated_programs","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"associated_programs","label":"title","reduce":function (associated_programs) { return associated_programs.id; },"placeholder":"Select Enrolled Program","options":_vm.allPrograms,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.simulation.associated_programs}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.title))])]}}]),model:{value:(_vm.simulation.associated_programs),callback:function ($$v) {_vm.$set(_vm.simulation, "associated_programs", $$v)},expression:"simulation.associated_programs"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.simulation.associated_programs.required && _vm.$v.simulation.associated_programs.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('page-separator',{attrs:{"title":"Feature this simulation for"}}),_c('b-form-group',{staticClass:"row-align-items-center"},[_c('label',{staticClass:"form-label"},[_vm._v(" Universities "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
          "Select universities for which you want to sponsor this simulation for, on CareCampus."
        ),expression:"\n          `Select universities for which you want to sponsor this simulation for, on CareCampus.`\n        ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons icon-16pt clickable-item text-muted"},[_vm._v("info")])]),_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"universities","label":"name","reduce":function (opt) { return opt.id; },"placeholder":"Select Universities","options":_vm.allUniversities,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
        var attributes = ref.attributes;
        var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.small_logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.small_logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.simulation.universities),callback:function ($$v) {_vm.$set(_vm.simulation, "universities", $$v)},expression:"simulation.universities"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }