<template>
  <div :class="containerClass">
    <page-header :title="$route.params.id ? 'Edit Sponsorship' : 'Post Sponsorship'" :container-class="null" />
    <div class="page-section">
      <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
        <b-form-group
          label="Post Sponsorship for"
          label-for="isOrg"
          label-class="form-label"
          v-slot="{ ariaDescribedby }"
          v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN"
        >
          <b-form-radio-group
            id="isOrg"
            v-model="isOrg"
            :options="isOrgOptions"
            :aria-describedby="ariaDescribedby"
            @change="fetchAllOrgs"
          ></b-form-radio-group>
        </b-form-group>

        <!-- v-if="this.getLoggedInUser.role_type !== USER_ROLE_TYPES.EMPLOYER" -->
        <b-form-group label="Category" label-for="category" label-class="form-label">
          <v-select
            id="category"
            class="form-control v-select-custom"
            label="text"
            :class="{
              'is-invalid': !$v.scholarship.category.required && $v.scholarship.category.$dirty
            }"
            v-model="scholarship.category"
            :reduce="category => category.value"
            placeholder="Select Category"
            :options="SPONSORSHIP_CATEGORIES"
            :clearable="false"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
          </v-select>
          <b-form-invalid-feedback
            :state="!$v.scholarship.category.required && $v.scholarship.category.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          label="Organization"
          label-for="organization"
          label-class="form-label"
          v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && isOrg"
        >
          <v-select
            id="organization"
            class="form-control v-select-custom"
            :class="{ 'is-invalid': !$v.org.required && $v.org.$dirty }"
            label="name"
            v-model="org"
            :reduce="opt => opt.id"
            placeholder="Select Organization"
            :options="allOrgs"
            :loading="areOrgsLoading"
            @input="changeSelectedOrg"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                  <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
          <b-form-invalid-feedback :state="!$v.org.required && $v.org.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          :label="`Employer${isOrg ? '(s)' : ''}`"
          label-for="employer"
          label-class="form-label"
          v-if="getLoggedInUser.role === USER_ROLES.SUPER_ADMIN || getLoggedInUser.role === USER_ROLES.ORGANIZATION"
        >
          <v-select
            id="employer"
            class="form-control v-select-custom"
            :class="{ 'is-invalid': !$v.employer.required && $v.employer.$dirty }"
            label="name"
            v-model="employer"
            :reduce="opt => opt.id"
            :placeholder="isOrg ? 'All Associated Employers' : 'Select Employer'"
            :options="allEmployers"
            :loading="areEmployersLoading"
            @input="changeSelectedEmployer"
            :multiple="isOrg"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                  <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
          <b-form-invalid-feedback :state="!$v.employer.required && $v.employer.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          :label="`School${isOrg ? '(s)' : ''}`"
          label-for="school"
          label-class="form-label"
          v-if="this.getLoggedInUser.role !== USER_ROLES.ORGANIZATION"
        >
          <v-select
            id="school"
            class="form-control v-select-custom"
            label="name"
            :class="{
              'is-invalid': !$v.scholarship.schools.required && $v.scholarship.schools.$dirty
            }"
            v-model="scholarship.schools"
            :reduce="schools => schools.id"
            :placeholder="`Select School${isOrg ? '(s)' : ''}`"
            :options="allSchools"
            :loading="areSchoolsLoading"
            @input="changedSchool"
            :multiple="isOrg"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                  <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
          <b-form-invalid-feedback
            :state="!$v.scholarship.schools.required && $v.scholarship.schools.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Associated Programs" label-for="associated_programs" label-class="form-label">
          <v-select
            id="associated_programs"
            class="form-control v-select-custom"
            label="title"
            :class="{
              'is-invalid': !$v.scholarship.associated_programs.required && $v.scholarship.associated_programs.$dirty
            }"
            v-model="scholarship.associated_programs"
            :reduce="associated_programs => associated_programs.id"
            placeholder="Select Enrolled Program"
            :options="allPrograms"
            multiple
            :loading="areProgramsLoading"
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>
            <template slot="option" slot-scope="option">
              <span>{{ option.title }}</span>
            </template>
          </v-select>
          <b-form-invalid-feedback
            :state="
              !$v.scholarship.associated_programs.required && $v.scholarship.associated_programs.$dirty ? false : null
            "
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Title" label-for="title" label-class="form-label">
          <b-form-input
            id="title"
            placeholder="Enter sponsorship's title."
            v-model.trim="scholarship.title"
            :state="!$v.scholarship.title.required && $v.scholarship.title.$dirty ? false : null"
          />
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group class="row-align-items-center">
          <label class="form-label">
            Image
            <!-- <i
              class="material-icons icon-16pt clickable-item text-muted"
              v-b-popover.hover.top="`Ideal dimensions for the logo should be 1280*720.`"
              >info</i
            > -->
          </label>
          <b-media class="align-items-center" vertical-align="center">
            <fmv-avatar :title="true" rounded size="lg" no-link slot="aside">
              <span v-if="isLogoUploading">...</span>
              <b-img
                :src="scholarship.logoUrl"
                class="img-fluid"
                width="40"
                alt="Logo"
                v-else-if="scholarship.logoUrl"
              />
              <i class="fas fa-university fa-lg" v-else></i>
            </fmv-avatar>

            <image-uploader
              @image="e => setImage(e, 'logo')"
              :isUploading="isLogoUploading"
              :isRequired="false"
              placeholder="Upload sponsorship's image"
            />
          </b-media>
        </b-form-group>

        <div class="row">
          <b-form-group label="Award Minimum" label-for="minimumAmount" label-class="form-label" class="col-md-6">
            <currency-input
              v-model="scholarship.minimumAmount"
              class="form-control"
              placeholder="Enter minimum amount"
              :allow-negative="false"
            />
            <!-- <b-form-invalid-feedback
              :state="!$v.scholarship.minimumAmount.required && $v.scholarship.minimumAmount.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            > -->
          </b-form-group>

          <b-form-group label="Award Maximum" label-for="maximumAmount" label-class="form-label" class="col-md-6">
            <currency-input
              v-model="scholarship.maximumAmount"
              class="form-control"
              placeholder="Enter maximum amount"
              :allow-negative="false"
              :value-range="{ ...(scholarship.minimumAmount && { min: scholarship.minimumAmount }) }"
            />
            <!-- <b-form-invalid-feedback
              :state="!$v.scholarship.maximumAmount.required && $v.scholarship.maximumAmount.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            > -->
          </b-form-group>
        </div>

        <b-form-group label="Summary" label-for="summary" label-class="form-label">
          <b-form-textarea
            id="summary"
            placeholder="Enter sponsorship's summary."
            :rows="5"
            maxLength="200"
            v-model="scholarship.summary"
            :state="!$v.scholarship.summary.required && $v.scholarship.summary.$dirty ? false : null"
          />
          <b-form-invalid-feedback
            :state="!$v.scholarship.summary.required && $v.scholarship.summary.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Description" label-for="description" label-class="form-label">
          <editor
            :init="{
              height: 400,
              menubar: 'edit view insert format tools table help',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
            }"
            v-model="scholarship.description"
            placeholder="Enter sponsorship's description."
          />
          <b-form-invalid-feedback
            :state="!$v.scholarship.description.required && $v.scholarship.description.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group class="row-align-items-center">
          <label class="form-label">
            Terms And Conditions
            <i
              class="material-icons icon-16pt clickable-item text-muted"
              v-b-popover.hover.top="`To be displayed when a student applies for In App sponsorship application`"
              >info</i
            >
          </label>
          <editor
            :init="{
              height: 400,
              menubar: 'edit view insert format tools table help',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
            }"
            v-model="scholarship.termsAndConditions"
            placeholder="Enter sponsorship's terms and conditions."
          />
          <b-form-invalid-feedback
            :state="
              !$v.scholarship.termsAndConditions.required && $v.scholarship.termsAndConditions.$dirty ? false : null
            "
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Student Type" label-for="studentType" label-class="form-label">
          <v-select
            id="studentType"
            class="form-control v-select-custom"
            :class="{ 'is-invalid': !$v.scholarship.studentType.required && $v.scholarship.studentType.$dirty }"
            required
            label="text"
            v-model="scholarship.studentType"
            :reduce="studentType => studentType.value"
            placeholder="Choose which students the sponsorship is for"
            :options="studentTypeOptions"
            multiple
          >
          </v-select>
          <b-form-invalid-feedback
            :state="!$v.scholarship.studentType.required && $v.scholarship.studentType.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Deadline" label-for="deadline" label-class="form-label">
          <date-picker
            id="deadline"
            placeholder="Select the deadline for sponsorship application"
            v-model="scholarship.deadline"
            type="date"
            format="MM/DD/YYYY"
            valueType="YYYY-MM-DD"
            style="width: 100%"
            :input-class="
              `mx-input ${
                !$v.scholarship.deadline.required && $v.scholarship.deadline.$dirty ? 'form-control is-invalid' : ''
              }`
            "
          />
          <b-form-invalid-feedback
            :state="!$v.scholarship.deadline.required && $v.scholarship.deadline.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Enable?" label-for="schFor" label-class="form-label" v-slot="{ ariaDescribedby }">
          <!-- v-if="getLoggedInUser.role !== USER_ROLES.SUPER_ADMIN" -->
          <b-form-radio-group
            id="schFor"
            v-model="scholarship.scholarship_type"
            :options="sponsorshipTypeOptions"
            :aria-describedby="ariaDescribedby"
          ></b-form-radio-group>
        </b-form-group>

        <b-form-group
          v-if="scholarship.scholarship_type === SPONSORSHIP_SUBMISSION_TYPES.EXTERNAL_SUBMISSION"
          label="Application Link"
          label-for="application-link"
          label-class="form-label"
        >
          <b-form-input
            id="title"
            placeholder="Enter sponsorship's application link."
            v-model="scholarship.applicationLink"
            :state="
              (!$v.scholarship.applicationLink.required || !$v.scholarship.applicationLink.url) &&
              $v.scholarship.applicationLink.$dirty
                ? false
                : null
            "
          />
          <b-form-invalid-feedback v-if="!$v.scholarship.applicationLink.url"
            >Not a valid link. Please make sure to enter a valid link e.g.
            https://www.example.com.</b-form-invalid-feedback
          >
          <b-form-invalid-feedback v-else>This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <!-- Address Fields -->
        <b-form-group label="Address" label-for="address" label-class="form-label">
          <b-form-input
            id="address"
            placeholder="Enter address"
            v-model="scholarship.address"
            :state="!$v.scholarship.address.required && $v.scholarship.address.$dirty ? false : null"
          />
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Region" label-for="region" label-class="form-label">
          <b-form-input
            id="region"
            placeholder="Enter region"
            v-model="scholarship.region"
            :state="!$v.scholarship.region.required && $v.scholarship.region.$dirty ? false : null"
          />
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <div class="row">
          <b-form-group label="Zipcode" label-for="zipcode" label-class="form-label" class="col-md-6">
            <b-form-input
              id="zipcode"
              placeholder="Enter zipcode"
              v-model="scholarship.zipcode"
              :state="!$v.scholarship.zipcode.required && $v.scholarship.zipcode.$dirty ? false : null"
            />
            <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="City" label-for="city" label-class="form-label" class="col-md-6">
            <b-form-input
              id="city"
              placeholder="Enter city"
              v-model="scholarship.city"
              :state="!$v.scholarship.city.required && $v.scholarship.city.$dirty ? false : null"
            />
            <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
          </b-form-group>
        </div>

        <div class="row">
          <b-form-group label="State" label-for="state" label-class="form-label" class="col-md-6">
            <v-select
              id="state"
              class="form-control v-select-custom"
              label="text"
              :state="!$v.scholarship.state.required && $v.scholarship.state.$dirty ? false : null"
              v-model="scholarship.state"
              :reduce="state => state.value"
              placeholder="Select State"
              :options="stateOptions"
            >
              <!-- <template #search="{ attributes, events }">
                <input class="vs__search" :required="!scholarship.state" v-bind="attributes" v-on="events" />
              </template> -->
            </v-select>
            <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Country" label-for="country" label-class="form-label" class="col-md-6">
            <v-select
              id="country"
              class="form-control v-select-custom"
              :class="{ 'is-invalid': !$v.scholarship.country.required && $v.scholarship.country.$dirty }"
              label="text"
              disabled
              v-model="scholarship.country"
              :reduce="country => country.value"
              placeholder="Select country"
              :options="countryOptions"
            >
              <template #search="{ attributes, events }">
                <input class="vs__search" :required="!scholarship.country" v-bind="attributes" v-on="events" />
              </template>
            </v-select>
            <b-form-invalid-feedback
              :state="!$v.scholarship.country.required && $v.scholarship.country.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>

        <!-- <div v-if="scholarship.category === SPONSORSHIP_CATEGORIES_TYPES.EMPLOYMENT"> -->
        <page-separator title="Employment details" />

        <b-form-group label="Work Commitment" label-for="work_commitment" label-class="form-label">
          <!-- <b-form-input
            v-model="scholarship.workCommitment"
            class="form-control"
            placeholder="Enter months"
            :allow-negative="false"
            type="number"
            :state="!$v.scholarship.workCommitment.required && $v.scholarship.workCommitment.$dirty ? false : null"
          /> -->
          <currency-input
            id="work_commitment"
            v-model="scholarship.workCommitment"
            class="form-control"
            placeholder="Enter months"
            :allow-negative="false"
            :precision="0"
            :currency="{ prefix: '', suffix: ' months' }"
          />
          <b-form-invalid-feedback
            :state="!$v.scholarship.workCommitment.required && $v.scholarship.workCommitment.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <div class="row">
          <b-form-group label="Type" label-for="type" label-class="form-label" class="col-md-6">
            <v-select
              id="type"
              class="form-control v-select-custom"
              :class="{ 'is-invalid': !$v.scholarship.type.required && $v.scholarship.type.$dirty }"
              label="text"
              v-model="scholarship.type"
              :reduce="option => option.value"
              placeholder="Select sponsorship type"
              :options="typeOptions"
            >
              <!-- <template #search="{ attributes, events }">
                <input class="vs__search" :required="!scholarship.type" v-bind="attributes" v-on="events" />
              </template> -->
            </v-select>
            <b-form-invalid-feedback :state="!$v.scholarship.type.required && $v.scholarship.type.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Schedule" label-for="schedule" label-class="form-label" class="col-md-6">
            <v-select
              id="schedule"
              class="form-control v-select-custom"
              :class="{ 'is-invalid': !$v.scholarship.schedule.required && $v.scholarship.schedule.$dirty }"
              label="text"
              v-model="scholarship.schedule"
              :reduce="option => option.value"
              placeholder="Select sponsorship schedule"
              :options="scheduleOptions"
            >
              <!-- <template #search="{ attributes, events }">
                <input class="vs__search" :required="!scholarship.schedule" v-bind="attributes" v-on="events" />
              </template> -->
            </v-select>
            <b-form-invalid-feedback
              :state="!$v.scholarship.schedule.required && $v.scholarship.schedule.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>
        </div>

        <!-- Pay/Salary details -->
        <div class="row">
          <b-form-group label="Minimum Pay" label-for="minimumPay" label-class="form-label" class="col-md-6">
            <currency-input
              v-model="scholarship.minimumPay"
              class="form-control"
              :class="{ 'is-invalid': !$v.scholarship.minimumPay.required && $v.scholarship.minimumPay.$dirty }"
              placeholder="Enter minimum pay"
              :allow-negative="false"
            />
            <b-form-invalid-feedback
              :state="!$v.scholarship.minimumPay.required && $v.scholarship.minimumPay.$dirty ? false : null"
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Maximum Pay" label-for="maximumPay" label-class="form-label" class="col-md-6">
            <currency-input
              v-model="scholarship.maximumPay"
              class="form-control"
              placeholder="Enter maximum pay (optional)"
              :allow-negative="false"
              :value-range="{ ...(scholarship.minimumPay && { min: scholarship.minimumPay }) }"
            />
          </b-form-group>
        </div>

        <b-form-group label="Pay Rate" label-for="pay-rate" label-class="form-label">
          <v-select
            id="pay-rate"
            class="form-control v-select-custom"
            :class="{ 'is-invalid': !$v.scholarship.payRate.required && $v.scholarship.payRate.$dirty }"
            label="text"
            v-model="scholarship.payRate"
            :reduce="rate => rate.value"
            placeholder="Select pay rate"
            :options="payRateOptions"
          >
            <!-- <template #search="{ attributes, events }">
              <input class="vs__search" :required="!scholarship.payRate" v-bind="attributes" v-on="events" />
            </template> -->
          </v-select>
          <b-form-invalid-feedback
            :state="!$v.scholarship.payRate.required && $v.scholarship.payRate.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <!-- Benefits -->
        <page-separator title="Benefits & Perks" />

        <b-form-group label="Benefits" label-for="benefits" label-class="form-label">
          <v-select
            id="benefits"
            class="form-control v-select-custom"
            :class="{ 'is-invalid': !$v.scholarship.benefits.required && $v.scholarship.benefits.$dirty }"
            label="text"
            v-model="scholarship.benefits"
            :reduce="benefit => benefit.value"
            placeholder="Select sponsorship benefits"
            :options="benefitOptions"
            multiple
          >
            <!-- <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template> -->
          </v-select>

          <b-form-input
            id="otherBenefits"
            class="mt-1"
            placeholder="Please specify"
            v-model="scholarship.otherBenefits"
            required
            v-if="(scholarship.benefits || []).includes('other')"
            :state="!$v.scholarship.otherBenefits.required && $v.scholarship.otherBenefits.$dirty ? false : null"
          />

          <b-form-invalid-feedback
            :state="
              (!$v.scholarship.benefits.required && $v.scholarship.benefits.$dirty) ||
              (!$v.scholarship.otherBenefits.required && $v.scholarship.otherBenefits.$dirty)
                ? false
                : null
            "
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          label="Offers Career Advancement"
          label-for="offersCareerAdvancement"
          label-class="form-label"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            id="offersCareerAdvancement"
            v-model="scholarship.offersCareerAdvancement"
            :options="yesNoOptions"
            :aria-describedby="ariaDescribedby"
            required
            :state="
              !$v.scholarship.offersCareerAdvancement.required && $v.scholarship.offersCareerAdvancement.$dirty
                ? false
                : null
            "
          ></b-form-radio-group>
          <b-form-invalid-feedback
            :state="
              !$v.scholarship.offersCareerAdvancement.required && $v.scholarship.offersCareerAdvancement.$dirty
                ? false
                : null
            "
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group
          label="Career Advancement Interest"
          label-for="careerAdvancementInterest"
          label-class="form-label"
          v-slot="{ ariaDescribedby }"
          v-if="scholarship.offersCareerAdvancement === false"
        >
          <b-form-radio-group
            id="careerAdvancementInterest"
            v-model="scholarship.careerAdvancementInterest"
            :options="yesNoOptions"
            :aria-describedby="ariaDescribedby"
            :required="!scholarship.offersCareerAdvancement"
            :state="
              !$v.scholarship.careerAdvancementInterest.required && $v.scholarship.careerAdvancementInterest.$dirty
                ? false
                : null
            "
          ></b-form-radio-group>
          <b-form-invalid-feedback
            :state="
              !$v.scholarship.careerAdvancementInterest.required && $v.scholarship.careerAdvancementInterest.$dirty
                ? false
                : null
            "
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <div v-else-if="scholarship.offersCareerAdvancement === true">
          <b-form-group label="Career Offerings" label-for="careerOfferings" label-class="form-label">
            <v-select
              id="careerOfferings"
              class="form-control v-select-custom"
              :class="{
                'is-invalid': !$v.scholarship.careerOfferings.required && $v.scholarship.careerOfferings.$dirty
              }"
              label="name"
              v-model="scholarship.careerOfferings"
              :reduce="career => career.id"
              placeholder="Select offered careers for career advancement"
              :options="careerOptions"
              :loading="areCareersLoading"
              multiple
            >
              <template #search="{ attributes, events }">
                <input
                  class="vs__search"
                  :required="!scholarship.careerOfferings.length"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>

              <template slot="option" slot-scope="option">
                <span>{{ option.name }}</span>
                <i v-if="option.id !== 'other'" class="ml-1">({{ $t(`careerPrefCategories.${option.category}`) }})</i>
              </template>
            </v-select>

            <b-form-input
              id="otherCareerOffering"
              class="mt-1"
              placeholder="Please specify"
              v-model="scholarship.otherCareerOffering"
              required
              v-if="(scholarship.careerOfferings || []).includes('other')"
              :state="
                !$v.scholarship.otherCareerOffering.required && $v.scholarship.otherCareerOffering.$dirty ? false : null
              "
            />
            <b-form-invalid-feedback
              :state="
                (!$v.scholarship.careerOfferings.required && $v.scholarship.careerOfferings.$dirty) ||
                (!$v.scholarship.otherCareerOffering.required && $v.scholarship.otherCareerOffering.$dirty)
                  ? false
                  : null
              "
              >This field is required.</b-form-invalid-feedback
            >
          </b-form-group>

          <b-form-group label="Career Offering Details" label-for="careerOfferingsDetails" label-class="form-label">
            <b-form-textarea
              id="careerOfferingsDetails"
              placeholder="Enter career offering details."
              :rows="5"
              v-model="scholarship.careerOfferingsDetails"
              required
              :state="
                !$v.scholarship.careerOfferingsDetails.required && $v.scholarship.careerOfferingsDetails.$dirty
                  ? false
                  : null
              "
            />
            <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <!-- </div> -->

        <b-form-group label-class="form-label">
          <b-form-checkbox v-model="scholarship.isActive" name="check-button" switch>
            Is sponsorship active?
          </b-form-checkbox>
        </b-form-group>

        <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
        </b-btn>
      </b-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import { required, requiredIf, url } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import Page from '../../../components/Page.vue';
import DatePicker from 'vue2-datepicker';
import { countries } from 'countries-list';
import { get, map } from 'lodash';
import {
  USER_ROLES,
  USER_ROLE_TYPES,
  JOB_SCHEDULE_OPTIONS,
  JOB_TYPE_OPTIONS,
  SPONSORSHIP_SUBMISSION_TYPES,
  SPONSORSHIP_CATEGORIES,
  SPONSORSHIP_CATEGORIES_TYPES
} from '../../../common/constants';
import { FmvAvatar } from 'fmv-avatar';
var UsaStates = require('usa-states').UsaStates;
import ImageUploader from '@/components/ImageUploader.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  components: { Editor, DatePicker, ImageUploader, FmvAvatar, PageSeparator, PageHeader },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Sponsorship' : 'Post Sponsorship',

      USER_ROLES,
      USER_ROLE_TYPES,
      tinemceApiKey: process.env.VUE_APP_TINEMCE_API_KEY,

      employer: null,
      isOrg: false,
      org: null,
      scholarship: {
        title: '',
        category: SPONSORSHIP_CATEGORIES_TYPES.EMPLOYMENT,
        studentType: [],
        applicationLink: '',
        description: '',
        summary: '',
        logoUrl: null,
        schools: null,
        minimumAmount: null,
        maximumAmount: null,
        associated_programs: [],
        isActive: true,
        deadline: null,

        termsAndConditions: `</li>\n<li>By submitting an application, you agree to provide accurate and truthful information.
</li>\n<li>You acknowledge that completing the application does not guarantee acceptance into any sponsored training program.
</li>\n<li>You understand that any financial assistance provided is intended solely for the purpose of training and related expenses, and must be used accordingly.
</li>\n<li>You agree to provide any additional information or documentation requested by the sponsor to verify your eligibility for the program.
</li>\n<li>You acknowledge that sponsorship may be subject to certain terms and conditions, such as maintaining a certain grade point average or completing the program within a specific time frame.
</li>\n<li>You agree to comply with all applicable laws and regulations, including those related to vocational training and employment.
</li>\n<li>You acknowledge that the sponsor may terminate the sponsorship at any time for any reason, including but not limited to failure to comply with program requirements or violation of any terms or conditions.
</li>\n<li>You understand that any personal information provided in connection with the application will be collected, used, and stored in accordance with applicable data protection laws and the sponsor's privacy policy.
</li>\n<li>You agree to release the sponsor, its affiliates, and its representatives from any liability or claim arising from your participation in the program.
</li>\n<li>You acknowledge that the terms of service may be updated from time to time, and that your continued participation in the program following any updates constitutes your acceptance of the revised terms.`,

        type: '',
        schedule: '',
        workCommitment: null,
        address: '',
        region: '',
        city: '',
        state: '',
        zipcode: '',
        country: 'United States',

        minimumPay: null,
        maximumPay: null,
        payRate: null,

        benefits: [],
        otherBenefits: null,
        offersCareerAdvancement: false,
        careerAdvancementInterest: false,
        careerOfferings: [],
        otherCareerOffering: null,
        careerOfferingsDetails: '',
        scholarship_type: SPONSORSHIP_SUBMISSION_TYPES.EXTERNAL_SUBMISSION
      },
      uploadFile: { logo: null },
      uploadPercent: { logo: 0 },
      uploadCancelTokenSource: { logo: null },
      isLogoUploading: false,
      isLoading: false,
      countryOptions: [],
      stateOptions: [],
      prevStateLength: null,
      allSchools: [],
      areSchoolsLoading: false,
      allEmployers: [],
      areEmployersLoading: false,
      allOrgs: [],
      areOrgsLoading: false,
      allPrograms: [],
      areProgramsLoading: false,
      typeOptions: JOB_TYPE_OPTIONS,
      scheduleOptions: JOB_SCHEDULE_OPTIONS,
      benefitOptions: [
        { value: 'healthcare_insurance', text: 'Healthcare Insurance' },
        { value: 'tuition_reimbursement', text: 'Tuition Reimbursement' },
        { value: 'childcare', text: 'Childcare' },
        { value: 'paid_mileage', text: 'Paid Mileage' },
        { value: 'other', text: 'Other' }
      ],
      studentTypeOptions: [
        { value: 'enrolled_students', text: 'Enrolled Students' },
        { value: 'new_students', text: 'New Students' }
      ],
      payRateOptions: [
        { value: 'hourly', text: 'Hourly' },
        { value: 'weekly', text: 'Weekly' },
        { value: 'bi_weekly', text: 'Bi-Weekly' },
        { value: 'monthly', text: 'Monthly' },
        { value: 'annually', text: 'Annually' }
      ],
      yesNoOptions: [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
      ],
      isOrgOptions: [
        { value: false, text: 'Employer' },
        { value: true, text: 'Organization' }
      ],
      sponsorshipTypeOptions: [
        { value: 'in_app_submission', text: 'In App Submission' },
        { value: 'external_submission', text: 'External Submission' }
      ],
      SPONSORSHIP_SUBMISSION_TYPES,
      SPONSORSHIP_CATEGORIES_TYPES,
      SPONSORSHIP_CATEGORIES
    };
  },

  validations() {
    return {
      employer: {
        required: requiredIf(() => this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN && !this.isOrg)
      },

      org: {
        required: requiredIf(() => this.getLoggedInUser.role_type === USER_ROLE_TYPES.SUPER_ADMIN && this.isOrg)
      },
      scholarship: {
        title: { required },
        description: { required },
        category: {
          required
        },
        // minimumAmount: { required },
        // maximumAmount: {},
        studentType: { required },
        schools: {
          required: requiredIf(() => this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER || !this.isOrg)
        },
        applicationLink: {
          required: requiredIf(
            () => this.scholarship.scholarship_type === SPONSORSHIP_SUBMISSION_TYPES.EXTERNAL_SUBMISSION
          ),
          url
        },
        associated_programs: { required },
        summary: { required },
        deadline: { required: false },

        termsAndConditions: {
          required: requiredIf(
            () => this.scholarship.scholarship_type === SPONSORSHIP_SUBMISSION_TYPES.IN_APP_SUBMISSION
          )
        },

        type: { required: false },
        schedule: { required: false },
        workCommitment: { required: false },

        address: { required: false },
        region: { required: false },
        city: { required: false },
        state: { required: false },
        zipcode: { required: false },
        country: { required: false },

        minimumPay: { required: false },
        maximumPay: {},
        payRate: { required: false },

        benefits: { required: false },
        otherBenefits: { required: requiredIf(() => this.scholarship.benefits.includes('other')) },
        offersCareerAdvancement: { required: false },
        careerAdvancementInterest: { required: requiredIf(() => this.scholarship.offersCareerAdvancement === false) },
        careerOfferings: { required: requiredIf(() => !!this.scholarship.offersCareerAdvancement) },
        otherCareerOffering: { required: requiredIf(() => this.scholarship.careerOfferings.includes('other')) },
        careerOfferingsDetails: { required: requiredIf(() => !!this.scholarship.offersCareerAdvancement) }
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.areProgramsLoading || this.areEmployersLoading || this.areSchoolsLoading;
    }
  },

  watch: {},

  methods: {
    ...mapActions('s3Upload', ['uploadToPresignedUrl']),
    ...mapActions('scholarships', [
      'createScholarship',
      'getScholarshipImageUploadPresignedUrl',
      'getScholarship',
      'updateScholarship'
    ]),
    ...mapActions('organization', ['getAllOrganizations']),
    ...mapActions('school', ['getAllSchools']),
    ...mapActions('employer', ['getAllEmployers']),
    ...mapActions('partnership', ['getAllPartneredSchools']),
    ...mapActions('program', ['getPublicSchoolPrograms', 'getAllPrograms']),
    ...mapActions('careerPreference', ['getAllCareerPreferences']),
    setImage(file, fileType) {
      this.uploadFile[fileType] = file;
      this.uploadImage(fileType);
    },
    fetchAllOrgs(value) {
      if (value) {
        this.fetchOrgs();
        this.employer = [];
        this.allEmployers = [];
        this.scholarship.associated_programs = [];
        this.scholarship.schools = [];
        this.fetchSchools();
        this.fetchPrograms();
      } else {
        this.org = null;
        this.employer = null;
        this.scholarship.schools = null;
        this.allSchools = [];
        this.allEmployers = [];
        this.allPrograms = [];

        this.fetchEmployers();
      }
    },
    changeSelectedOrg(value) {
      if (!this.org) {
        this.employer = [];
      }
      if (value) {
        this.employer = [];
        this.scholarship.schools = [];
        this.scholarship.associated_programs = [];
        this.fetchEmployers(value);
      } else {
        this.scholarship.schools = [];
        this.allEmployers = [];

        this.scholarship.associated_programs = [];
      }
    },
    async fetchOrgs() {
      this.areOrgsLoading = true;
      const response = await this.getAllOrganizations({});
      this.allOrgs = response.data;

      this.areOrgsLoading = false;
    },
    async fetchEmployers(org_id = null) {
      this.areEmployersLoading = true;
      let resp;

      if (org_id && this.isOrg) {
        resp = await this.getAllEmployers({
          organization__id: org_id
        });
      } else {
        resp = await this.getAllEmployers({});
      }
      this.allEmployers = resp.data;

      this.areEmployersLoading = false;
    },
    fillInOptions() {
      var usStates = new UsaStates();
      this.stateOptions = this.stateOptions.concat(
        map(usStates.states, state => ({
          value: state.name,
          text: state.name
        }))
      );
      this.countryOptions = this.countryOptions.concat(
        map(countries, country => ({
          value: country.name,
          text: country.name
        }))
      );
    },
    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          employers:
            this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER
              ? [this.getLoggedInUser.linked_entity.id]
              : !this.isOrg
              ? [this.employer]
              : this.employer,
          posted_by_org:
            this.getLoggedInUser.role_type === USER_ROLE_TYPES.ORGANIZATION
              ? this.getLoggedInUser.linked_entity.id
              : this.isOrg && this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN
              ? this.org
              : null,
          schools: !this.isOrg ? [this.scholarship.schools] : this.scholarship.schools,
          programs:
            this.scholarship.associated_programs[0].id === 'other' ||
            this.scholarship.associated_programs[0] === 'other'
              ? []
              : this.scholarship.associated_programs,
          title: this.scholarship.title,
          image_url: this.scholarship.logoUrl,
          min_amount: this.scholarship.minimumAmount,
          max_amount: this.scholarship.maximumAmount,
          summary: this.scholarship.summary,
          description: this.scholarship.description,
          student_type: this.scholarship.studentType,
          deadline: this.scholarship.deadline,
          external_link: this.scholarship.applicationLink,
          is_active: this.scholarship.isActive,
          scholarship_type: this.scholarship.scholarship_type,
          ...(this.isOrg && { organization: this.org }),
          // !!!!!!!!!!!!
          terms_conditions: this.scholarship.termsAndConditions,
          type: this.scholarship.type,
          schedule: this.scholarship.schedule,
          work_commitment: this.scholarship.workCommitment,
          address: this.scholarship.address,
          region: this.scholarship.region,
          city: this.scholarship.city,
          state: this.scholarship.state,
          zipcode: this.scholarship.zipcode,
          country: this.scholarship.country,

          minimum_pay: this.scholarship.minimumPay,
          maximum_pay: this.scholarship.maximumPay,
          pay_rate: this.scholarship.payRate,

          benefits: this.scholarship.benefits,
          other_benefits: this.scholarship.benefits.includes('other') ? this.scholarship.otherBenefits : null,
          offers_career_advancement: this.scholarship.offersCareerAdvancement,
          career_advancement_interest: this.scholarship.careerAdvancementInterest,
          career_offerings: this.scholarship.offersCareerAdvancement
            ? this.scholarship.careerOfferings.filter(career => career !== 'other')
            : [],

          other_career_offering:
            this.scholarship.offersCareerAdvancement && this.scholarship.careerOfferings.includes('other')
              ? this.scholarship.otherCareerOffering
              : null,

          career_offerings_details: this.scholarship.offersCareerAdvancement
            ? this.scholarship.careerOfferingsDetails
            : null,

          categories: this.scholarship.category
        };

        try {
          if (this.$route.params.id) {
            await this.updateScholarship({
              id: this.$route.params.id,
              data
            });
            this.makeToast({ variant: 'success', msg: 'Sponsorship Successfully Updated' });
          } else {
            await this.createScholarship(data);
            this.makeToast({ variant: 'success', msg: 'Sponsorship Successfully Posted' });
          }
          setTimeout(() => this.$router.push({ name: 'scholarships-management-list' }), 250);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
    changeSelectedEmployer(value) {
      if (!this.isOrg) {
        this.allSchools = [];
        this.scholarship.schools = null;
        this.allPrograms = [];
        this.scholarship.associated_programs = [];
      }

      if (value) {
        this.fetchSchools();
      } else {
        this.employer = null;
      }
    },
    changedSchool(value) {
      if (!this.isOrg) {
        this.allPrograms = [];
        this.scholarship.associated_programs = [];
      }

      if (value) {
        const school = this.allSchools.find(school => school.id === value);
        if (!this.isOrg) {
          this.allPrograms = get(school, 'partnership_programs', []);

          if (!this.allPrograms.length) {
            this.allPrograms.push({ title: 'Other', id: 'other' });
          }
        }
      } else {
        this.scholarship.schools = null;
      }
    },
    async fetchCareerPreferences() {
      this.areCareersLoading = true;

      const response = await this.getAllCareerPreferences({});
      this.careerOptions = this.careers = response.data;
      this.careerOptions.push({ id: 'other', name: 'Other' });

      this.areCareersLoading = false;
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;
      try {
        // const response = await this.getAllPartneredSchools({});
        if (this.isOrg) {
          const response = await this.getAllSchools({});

          this.allSchools = response.data;
        } else if (!this.isOrg) {
          const response = await this.getAllPartneredSchools({ employer: this.employer });

          this.allSchools = response.data;
        } else {
          const response = await this.getAllPartneredSchools({});

          this.allSchools = response.data;
        }
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.areSchoolsLoading = false;
    },
    async fetchPrograms() {
      this.areProgramsLoading = true;
      try {
        if (this.isOrg) {
          const response = await this.getAllPrograms({ ordering: 'id' });
          this.allPrograms = response.data;

          if (!this.allPrograms.length) {
            this.allPrograms.push({ title: 'Other', id: 'other' });
          }
        } else {
          const response = await this.getPublicSchoolPrograms({ school: this.scholarship.schools });
          this.allPrograms = response.data;

          if (!this.allPrograms.length) {
            this.allPrograms.push({ title: 'Other', id: 'other' });
          }
        }
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.areProgramsLoading = false;
    },
    async fetchScholarship() {
      this.isLoading = true;
      try {
        const resp = await this.getScholarship(this.$route.params.id);

        this.isOrg = resp.data.posted_by_org ? true : false;
        await new Promise(r => setTimeout(r, 500));
        this.scholarship.schools = resp.data.posted_by_org
          ? resp.data.schools.map(emp => emp.id)
          : resp.data.schools[0].id;
        this.scholarship.associated_programs = resp.data.programs.length
          ? resp.data.programs.map(program => program.id)
          : [{ title: 'Other', id: 'other' }];
        this.scholarship.title = resp.data.title;
        this.scholarship.applicationLink = resp.data.external_link;
        this.scholarship.summary = resp.data.summary;
        this.scholarship.studentType = resp.data.student_type;
        this.scholarship.isActive = resp.data.is_active;
        this.scholarship.logoUrl = resp.data.image_url;
        this.scholarship.description = resp.data.description;
        this.scholarship.termsAndConditions = resp.data.terms_conditions;
        this.scholarship.minimumAmount = resp.data.min_amount;
        this.scholarship.maximumAmount = resp.data.max_amount;
        this.scholarship.deadline = resp.data.deadline;
        this.employer = resp.data.posted_by_org ? resp.data.employers.map(emp => emp.id) : resp.data.employers[0].id;

        this.org = get(resp.data.posted_by_org, 'id');
        this.scholarship.type = resp.data.type;
        this.scholarship.schedule = resp.data.schedule;
        this.scholarship.workCommitment = resp.data.work_commitment;

        this.scholarship.address = resp.data.address;
        this.scholarship.region = resp.data.region;

        this.scholarship.city = resp.data.city;
        this.scholarship.state = resp.data.state;
        this.scholarship.zipcode = resp.data.zipcode;
        this.scholarship.country = resp.data.country;

        this.scholarship.minimumPay = resp.data.minimum_pay;
        this.scholarship.maximumPay = resp.data.maximum_pay;
        this.scholarship.payRate = resp.data.pay_rate;

        this.scholarship.benefits = resp.data.benefits;
        this.scholarship.otherBenefits = resp.data.other_benefits;
        this.scholarship.offersCareerAdvancement = resp.data.offers_career_advancement;
        this.scholarship.careerAdvancementInterest = resp.data.career_advancement_interest;
        this.scholarship.careerOfferings = map(resp.data.career_offerings, 'id');
        this.scholarship.otherCareerOffering = resp.data.other_career_offering;
        this.scholarship.scholarship_type = resp.data.scholarship_type;
        if (this.scholarship.otherCareerOffering) {
          this.scholarship.careerOfferings.push('other');
        }
        this.scholarship.careerOfferingsDetails = resp.data.career_offerings_details;
        this.scholarship.category = resp.data.categories;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
    async uploadImage(uploadType) {
      this.uploadCancelTokenSource[uploadType] = axios.CancelToken.source();
      this.isLogoUploading = uploadType === 'logo';

      try {
        const urlResp = await this.getScholarshipImageUploadPresignedUrl({
          file_name: this.uploadFile[uploadType].name,
          content_type: this.uploadFile[uploadType].type
        });

        await this.uploadToPresignedUrl({
          url: urlResp.upload_url,
          file: this.uploadFile[uploadType],
          config: {
            onUploadProgress: function(progressEvent) {
              this.uploadPercent[uploadType] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }.bind(this),
            cancelToken: this.uploadCancelTokenSource[uploadType].token
          }
        });
        if (uploadType === 'logo') {
          this.scholarship.logoUrl = urlResp.upload_url.split('?')[0];
        }
      } catch (error) {
        this.uploadFile[uploadType] = null;
      }

      this.uploadCancelTokenSource[uploadType] = null;
      this.uploadPercent[uploadType] = 0;

      if (uploadType === 'logo') this.isLogoUploading = false;
    }
  },

  async mounted() {
    this.fillInOptions();
    this.fetchCareerPreferences();
    if (this.getLoggedInUser.role === USER_ROLES.ORGANIZATION) {
      this.isOrg = true;
      this.fetchPrograms();
    }
    if (this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN && this.$route.params.id) {
      this.fetchOrgs();
    }
    if (this.$route.params.id) {
      await this.fetchScholarship();
      if (
        this.getLoggedInUser.role === USER_ROLES.SUPER_ADMIN ||
        this.getLoggedInUser.role === USER_ROLES.ORGANIZATION
      ) {
        this.fetchPrograms();
        // this.changeSelectedOrg(this.org);
        this.fetchEmployers(this.org);
      }
      if (this.getLoggedInUser.role !== USER_ROLES.ORGANIZATION) {
        await this.fetchSchools();
        if (!this.isOrg) {
          const school = this.allSchools.find(school => school.id === this.scholarship.schools);
          this.allPrograms = get(school, 'partnership_programs', []);
        }
      }
    } else {
      if (this.getLoggedInUser.role_type === USER_ROLE_TYPES.EMPLOYER) {
        this.fetchSchools();
      } else {
        this.fetchEmployers();
      }
    }
  }
};
</script>
