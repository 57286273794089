var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[(_vm.getLoggedInUser.role === _vm.USER_ROLES.SUPER_ADMIN)?_c('b-form-group',{attrs:{"label":"Post Job for","label-for":"isOrg","label-class":"form-label"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"id":"isOrg","options":_vm.isOrgOptions,"aria-describedby":ariaDescribedby},on:{"change":_vm.fetchOrgsForSelection},model:{value:(_vm.isOrg),callback:function ($$v) {_vm.isOrg=$$v},expression:"isOrg"}})]}}],null,false,2542522452)}):_vm._e(),(_vm.getLoggedInUser.role === _vm.USER_ROLES.SUPER_ADMIN && _vm.isOrg)?_c('b-form-group',{attrs:{"label":"Organization","label-for":"organization","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.job.org.required && _vm.$v.job.org.$dirty },attrs:{"id":"organization","label":"name","reduce":function (opt) { return opt.id; },"placeholder":"Select Organization","options":_vm.allOrgs,"loading":_vm.areOrgsLoading},on:{"input":_vm.changeSelectedOrg},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,3108784906),model:{value:(_vm.job.org),callback:function ($$v) {_vm.$set(_vm.job, "org", $$v)},expression:"job.org"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.job.org.required && _vm.$v.job.org.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),(_vm.getLoggedInUser.role === _vm.USER_ROLES.SUPER_ADMIN || _vm.getLoggedInUser.role === _vm.USER_ROLES.ORGANIZATION)?_c('b-form-group',{attrs:{"label":_vm.$t('employerMsgs.employer'),"label-for":"employer","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.job.employer.required && _vm.$v.job.employer.$dirty },attrs:{"id":"employer","label":"name","reduce":function (opt) { return opt.id; },"placeholder":"Select Employer","options":_vm.allEmployers,"loading":_vm.areEmployersLoading},on:{"input":_vm.changeSelectedEmployer},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.job.employer}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,2780829620),model:{value:(_vm.job.employer),callback:function ($$v) {_vm.$set(_vm.job, "employer", $$v)},expression:"job.employer"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.job.employer.required && _vm.$v.job.employer.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),(_vm.job.job_type === _vm.JOB_TYPES.LOCAL)?_c('b-form-group',{staticClass:"row-align-items-center"},[_c('label',{staticClass:"form-label"},[_vm._v(" School "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:("This job will be displayed to your partnered school on tranistion portal."),expression:"`This job will be displayed to your partnered school on tranistion portal.`",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons icon-16pt clickable-item text-muted"},[_vm._v("info")])]),_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.job.schools.required && _vm.$v.job.schools.$dirty },attrs:{"id":"school","label":"name","reduce":function (opt) { return opt.id; },"placeholder":"Select School","options":_vm.allSchools,"loading":_vm.areSchoolsLoading},on:{"input":_vm.schoolChanged},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.job.schools}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}],null,false,4062031398),model:{value:(_vm.job.schools),callback:function ($$v) {_vm.$set(_vm.job, "schools", $$v)},expression:"job.schools"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.job.schools.required && _vm.$v.job.schools.$dirty ? false : null}},[_vm._v("This field is required.")])],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Associated Programs","label-for":"associated_programs","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"associated_programs","label":"title","reduce":function (associated_programs) { return associated_programs.id; },"placeholder":"Select Enrolled Program","options":_vm.allPrograms,"multiple":_vm.job.job_type === _vm.JOB_TYPES.LOCAL ? false : true},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.job.associated_programs}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.title))])]}}]),model:{value:(_vm.job.associated_programs),callback:function ($$v) {_vm.$set(_vm.job, "associated_programs", $$v)},expression:"job.associated_programs"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.job.associated_programs.required && _vm.$v.job.associated_programs.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('page-separator',{attrs:{"title":"Feature this job for"}}),_c('b-form-group',{staticClass:"row-align-items-center"},[_c('label',{staticClass:"form-label"},[_vm._v(" Universities "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:("Select universities for which you want to sponsor this job for, on CareCampus."),expression:"`Select universities for which you want to sponsor this job for, on CareCampus.`",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons icon-16pt clickable-item text-muted"},[_vm._v("info")])]),_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"universities","label":"name","reduce":function (opt) { return opt.id; },"placeholder":"Select Universities","options":_vm.allUniversities,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search"},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex align-items-center"},[_c('fmv-avatar',{attrs:{"slot":"aside","title":true,"title-class":"bg-transparent","rounded":"","no-link":"","size":"xs"},slot:"aside"},[(option.small_logo_url)?_c('b-img',{staticClass:"img-fluid",attrs:{"src":option.small_logo_url,"width":"20","alt":"Logo"}}):_c('i',{staticClass:"fas fa-university fa-lg"})],1),_c('span',[_vm._v(_vm._s(option.name))])],1)]}},{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.job.universities),callback:function ($$v) {_vm.$set(_vm.job, "universities", $$v)},expression:"job.universities"}})],1),_c('b-form-group',{staticClass:"row-align-items-center"},[_c('label',{staticClass:"form-label"},[_vm._v(" Workforce Programs "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:("Select workforce programs for which you want to sponsor this job for, on CareClimb."),expression:"`Select workforce programs for which you want to sponsor this job for, on CareClimb.`",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons icon-16pt clickable-item text-muted"},[_vm._v("info")])]),_c('v-select',{staticClass:"form-control v-select-custom",attrs:{"id":"best_school_cities","label":"name","reduce":function (best_cities) { return best_cities.id; },"placeholder":"Select Workforce Programs","options":_vm.allCities,"loading":_vm.areCitiesLoading,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.job.best_school_cities}},'input',attributes,false),events))]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.name))])]}}]),model:{value:(_vm.job.best_school_cities),callback:function ($$v) {_vm.$set(_vm.job, "best_school_cities", $$v)},expression:"job.best_school_cities"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }