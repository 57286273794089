var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{staticClass:"col-md-12 px-0 page-section pt-0",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Caregiving Experience","label-for":"caregiving_experience","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.job.caregiving_experience.required && _vm.$v.job.caregiving_experience.$dirty },attrs:{"id":"caregiving_experience","label":"text","reduce":function (exp) { return exp.value; },"placeholder":"Select required experience","options":_vm.workExpOptions},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.job.caregiving_experience}},'input',attributes,false),events))]}}]),model:{value:(_vm.job.caregiving_experience),callback:function ($$v) {_vm.$set(_vm.job, "caregiving_experience", $$v)},expression:"job.caregiving_experience"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.$v.job.caregiving_experience.required && _vm.$v.job.caregiving_experience.$dirty ? false : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Required Certifications","label-for":"required_certs","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.job.required_certs.required && _vm.$v.job.required_certs.$dirty },attrs:{"id":"required_certs","label":"text","reduce":function (exp) { return exp.value; },"placeholder":"Select required certifications","options":_vm.certificationOptions,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.job.required_certs.length}},'input',attributes,false),events))]}}]),model:{value:(_vm.job.required_certs),callback:function ($$v) {_vm.$set(_vm.job, "required_certs", $$v)},expression:"job.required_certs"}}),((_vm.job.required_certs || []).includes('other'))?_c('b-form-input',{staticClass:"mt-1",attrs:{"id":"other_required_certs","placeholder":"Please specify","required":"","state":!_vm.$v.job.other_required_certs.required && _vm.$v.job.other_required_certs.$dirty ? false : null},model:{value:(_vm.job.other_required_certs),callback:function ($$v) {_vm.$set(_vm.job, "other_required_certs", $$v)},expression:"job.other_required_certs"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":(!_vm.$v.job.other_required_certs.required && _vm.$v.job.other_required_certs.$dirty) ||
        (!_vm.$v.job.required_certs.required && _vm.$v.job.required_certs.$dirty)
          ? false
          : null}},[_vm._v("This field is required.")])],1),_c('b-form-group',{attrs:{"label":"Required Records","label-for":"required_records","label-class":"form-label"}},[_c('v-select',{staticClass:"form-control v-select-custom",class:{ 'is-invalid': !_vm.$v.job.required_records.required && _vm.$v.job.required_records.$dirty },attrs:{"id":"required_records","label":"text","reduce":function (exp) { return exp.value; },"placeholder":"Select required records","options":_vm.recordOptions,"multiple":""},scopedSlots:_vm._u([{key:"search",fn:function(ref){
          var attributes = ref.attributes;
          var events = ref.events;
return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":!_vm.job.required_records.length}},'input',attributes,false),events))]}}]),model:{value:(_vm.job.required_records),callback:function ($$v) {_vm.$set(_vm.job, "required_records", $$v)},expression:"job.required_records"}}),((_vm.job.required_records || []).includes('other'))?_c('b-form-input',{staticClass:"mt-1",attrs:{"id":"other_required_records","placeholder":"Please specify","required":"","state":!_vm.$v.job.other_required_records.required && _vm.$v.job.other_required_records.$dirty ? false : null},model:{value:(_vm.job.other_required_records),callback:function ($$v) {_vm.$set(_vm.job, "other_required_records", $$v)},expression:"job.other_required_records"}}):_vm._e(),_c('b-form-invalid-feedback',{attrs:{"state":(!_vm.$v.job.other_required_records.required && _vm.$v.job.other_required_records.$dirty) ||
        (!_vm.$v.job.required_records.required && _vm.$v.job.required_records.$dirty)
          ? false
          : null}},[_vm._v("This field is required.")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }