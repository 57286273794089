<template>
  <div :class="containerClass">
    <page-header :title="$route.params.id ? 'Edit Sponsorship' : 'Post Sponsorship'" :container-class="null" />
    <div class="page-section">
      <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
        <b-form-group :label="`Schools`" label-for="school" label-class="form-label">
          <v-select
            id="school"
            class="form-control v-select-custom"
            label="name"
            :class="{
              'is-invalid': !$v.scholarship.schools.required && $v.scholarship.schools.$dirty
            }"
            v-model="scholarship.schools"
            :reduce="schools => schools.id"
            :placeholder="`Select Schools`"
            :options="allSchools"
            :loading="areSchoolsLoading"
            multiple
          >
            <template #search="{ attributes, events }">
              <input class="vs__search" v-bind="attributes" v-on="events" />
            </template>

            <template slot="option" slot-scope="option">
              <div class="d-flex align-items-center">
                <fmv-avatar :title="true" title-class="bg-transparent" rounded no-link slot="aside" size="xs">
                  <b-img :src="option.logo_url" class="img-fluid" width="20" alt="Logo" v-if="option.logo_url" />
                  <i class="fas fa-university fa-lg" v-else></i>
                </fmv-avatar>
                <span>{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              {{ option.name }}
            </template>
          </v-select>
          <b-form-invalid-feedback
            :state="!$v.scholarship.schools.required && $v.scholarship.schools.$dirty ? false : null"
            >This field is required.</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Title" label-for="title" label-class="form-label">
          <b-form-input
            id="title"
            placeholder="Enter sponsorship's title."
            v-model="scholarship.title"
            :state="!$v.scholarship.title.required && $v.scholarship.title.$dirty ? false : null"
          />
          <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Organization Name" label-for="title" label-class="form-label">
          <b-form-input id="organization" placeholder="Enter organization's name." v-model="scholarship.organization" />
        </b-form-group>

        <b-btn variant="primary" :disabled="isFormLoading" style="width: 150px" type="submit" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>{{ $route.params.id ? $t('update') : $t('add') }}</span>
        </b-btn>
      </b-form>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import Page from '@/components/Page.vue';
import { FmvAvatar } from 'fmv-avatar';
import PageHeader from '@/components/Ui/PageHeader.vue';

export default {
  components: { PageHeader, FmvAvatar },
  extends: Page,

  data() {
    return {
      title: this.$route.params.id ? 'Edit Sponsorship' : 'Post Sponsorship',

      scholarship: {
        title: '',
        organization: '',
        schools: null
      },

      allSchools: [],
      isLoading: false,
      areSchoolsLoading: false
    };
  },

  validations() {
    return {
      scholarship: {
        title: { required },

        schools: {
          required
        }
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    isFormLoading() {
      return this.isLoading || this.areSchoolsLoading;
    }
  },

  watch: {},

  methods: {
    ...mapActions('scholarships', ['createScholarship', 'getScholarship', 'updateScholarship']),

    ...mapActions('school', ['getAllSchools']),

    async onSubmit() {
      this.isLoading = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          schools: !this.scholarship.schools.length ? [this.scholarship.schools] : this.scholarship.schools,
          title: this.scholarship.title,
          external_organization: this.scholarship.organization,
          scholarship_type: 'by_superadmin'
        };

        try {
          if (this.$route.params.id) {
            await this.updateScholarship({
              id: this.$route.params.id,
              data
            });
            this.makeToast({ variant: 'success', msg: 'Sponsorship Successfully Updated' });
          } else {
            await this.createScholarship(data);
            this.makeToast({ variant: 'success', msg: 'Sponsorship Successfully Posted' });
          }
          setTimeout(() => this.$router.push({ name: 'external-scholarships-list' }), 250);
        } catch (err) {
          this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
        }
      } else {
        this.makeToast({ variant: 'danger', msg: 'Please fill all fields correctly.' });
      }
      this.isLoading = false;
    },
    async fetchSchools() {
      this.areSchoolsLoading = true;
      try {
        const response = await this.getAllSchools({});

        this.allSchools = response.data;
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.areSchoolsLoading = false;
    },

    async fetchScholarship() {
      this.isLoading = true;
      try {
        const resp = await this.getScholarship(this.$route.params.id);

        this.scholarship.schools = resp.data.schools.map(emp => emp.id);
        this.scholarship.title = resp.data.title;
        this.scholarship.organization = resp.data.external_organization;
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    }
  },

  async mounted() {
    await this.fetchSchools();
    if (this.$route.params.id) {
      await this.fetchScholarship();
    }
  }
};
</script>
