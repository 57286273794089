<template>
  <b-form class="col-md-12 px-0 page-section pt-0" @submit.prevent="onSubmit">
    <b-form-group label="Question Flow" label-for="questionFlow" label-class="form-label">
      <b-form-radio-group
        id="questionFlow"
        v-model="simulation.questionFlow"
        :options="questionFlowOptions"
        required
        :state="!$v.simulation.questionFlow.required && $v.simulation.questionFlow.$dirty ? false : null"
      ></b-form-radio-group>
      <b-form-invalid-feedback
        :state="!$v.simulation.questionFlow.required && $v.simulation.questionFlow.$dirty ? false : null"
        >This field is required.</b-form-invalid-feedback
      >
    </b-form-group>

    <b-form-group label="Questions" label-for="questions" label-class="form-label">
      <b-form-textarea
        id="questions"
        placeholder="Enter questions"
        :rows="5"
        v-model="simulation.questions"
        required
        :state="!$v.simulation.questions.required && $v.simulation.questions.$dirty ? false : null"
      />
      <b-form-invalid-feedback>This field is required.</b-form-invalid-feedback>
    </b-form-group>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { pick } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'JobBenefitsForm',
  components: {},

  props: {
    simulationData: { type: Object, required: true }
  },

  data() {
    return {
      simulation: {
        questionFlow: null,
        questions: ''
      },

      questionFlowOptions: [
        { value: 'sequential', text: 'Sequential' },
        { value: 'random', text: 'Random' }
      ]
    };
  },

  validations() {
    return {
      simulation: {
        questionFlow: { required },
        questions: { required }
      }
    };
  },

  computed: {},

  watch: {
    simulationData: {
      handler() {
        this.simulation = pick(this.simulationData, ['questionFlow', 'questions']);
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('careerPreference', ['getAllCareerPreferences']),

    validateFormData() {
      this.$v.$touch();
      return {
        isValid: !this.$v.$invalid,
        formData: { ...this.simulation }
      };
    }
  },

  async mounted() {}
};
</script>
